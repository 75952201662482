import React, { useReducer } from 'react';

import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import formReducer from '../reducers/FormReducer';


const initialNewsLetterState = {
    firstName: "",
    lastName: "",
    emailAddress: ""
};


const NewsLetterModal = (props) => {

    const [state, dispatch] = useReducer(formReducer, initialNewsLetterState);

    const handleTextChange = (event) => {
        dispatch({
            type: "HANDLE NEWSLETTER INPUT2",
            field: event.target.name,
            payload: event.target.value,
        });
    };

    const handleFormSubmit = (event) => {
        let data = state;
        handleSendData(data);
        //console.log(data);
        event.preventDefault();
    }

    const handleSendData = (data) => {
        axios.post(`${process.env.REACT_APP_MAILER_API_URL}/gas-fill-news-letter-form`, data)
        .then(response => {
            let result = response.data;
            if (result.status === "Success") {
                console.log("Contact Added Succesfully");
                props.notifyNewsLetterSuccess();
                formReset();
                props.onHide();
            }else {
                console.log("Duplicate Records");
                props.notifyDuplicateNewsLetterRecord();
                formReset();
                props.onHide();
            }
        });
    }
    
    // RESET FORM FUNCTION
    const formReset = (event) =>{
        dispatch({type: "HANDLE FORM RESET"});
    }


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
         >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="newsletter-spacer">
          Join our Mailing List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="newsletter-spacer">
        <h5 id='modal-subheader' className="newsletter-spacer">Please provide your Name details to subscribe to our newsletter.</h5>
        <br/>
        <Form method="POST" onSubmit={handleFormSubmit} className="newsletter-spacer">
        <Row className="align-items-center">
            <Col xs="sm">
                <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                    First Name
                </Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Text><PersonIcon /></InputGroup.Text>
                    <Form.Control type="text" id="inlineFormInputGroup" name="firstName" value={state.firstName} placeholder="First Name" required={true} onChange={handleTextChange}/>
                </InputGroup>
            </Col>
            <Col xs="sm">
                <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
                    Last Name
                </Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Text><PermIdentityIcon /></InputGroup.Text>
                    <Form.Control type="text" id="inlineFormInputGroup" name="lastName" value={state.lastName} placeholder="Last Name" required={true} onChange={handleTextChange}/>
                </InputGroup>
            </Col>
            <Col xs="xs">
                <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                    Email Address
                </Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Text><AlternateEmailIcon /></InputGroup.Text>
                    <Form.Control type="email" id="inlineFormInputGroup" name="emailAddress" value={state.emailAddress = props.emailAddress} required={true} onChange={handleTextChange} disabled/>
                </InputGroup>
            </Col>
        </Row>
        <br/>
        <Modal.Footer>
        <Button 
            type="submit" 
            id="newsletter-modal-button" 
            name='submit' 
            value='submitRequest'
            /**handles the Google Analytics triggering when the form field (companyName, contactPerson, emailAddress, contactNumber) are all filled out*/
            onClick={
                state.firstName === undefined ? null
                : state.lastName === undefined ? null
                : state.emailAddress === undefined ? null
                : props.eventTrack.bind(this, "news_letter_form_submissions", "forms_submissions", "newsletter_form")
            }
        >
            Join Our Mailing List
        </Button>
        </Modal.Footer>

    </Form>
      </Modal.Body>
      
    </Modal>
    );
};

export default NewsLetterModal;