import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

import bannerVideo from '../website-images/gasfill-bg-video.mp4';
import bannerVideo1 from '../website-images/gasfill-bg-video.webm';
import bannerVideo2 from '../website-images/gasfill-bg-video.ogv';
import bannerVideo3 from '../website-images/gasfill-bg-video.avi';
import fallbackBanner from '../website-images/gasfill-bg-video.gif';
import GasFillLogoWhite from '../website-images/gasfill-logo-white.svg';

const Banner = (props) => {


    return (
        <Carousel fade className='banner-carousel'>
            <Carousel.Item className='banner-carousel-item'>
                <video className="banner-video" id='videoID' controls={true} preload="auto" autoPlay={true} loop={true} muted={true} playsInline={true} poster={fallbackBanner}>
                    <source  src={bannerVideo} type="video/mp4"/>
                    <source  src={bannerVideo1} type="video/webm"/>
                    <source  src={bannerVideo2} type="video/ogv"/>
                    <source  src={bannerVideo3} type="video/avi"/>
                </video>
                <div className="overlay"></div>
                <Carousel.Caption className='banner-carousel-caption'>
                <img className="gasfill-logo" src={GasFillLogoWhite} alt="gas fill australia logo" data-aos="zoom-in-up"/>
                <div className="main-banner-button-container" data-aos="fade-left">
                    <Button variant='outlined' href='tel:1300225542' startIcon={<CallRoundedIcon className='main-banner-button-desktop-icon'/>} className='main-banner-button-desktop'>1300-225-542</Button>
                    <Button variant='outlined' href='mailto:info@ciscientific.com' startIcon={<EmailRoundedIcon className='main-banner-button-desktop-icon'/>} className='main-banner-button-desktop'>info@ciscientific.com</Button>
                    <IconButton href='tel:1300225542' className='main-banner-button-mobile'><CallRoundedIcon className='main-banner-button-mobile-icon'/></IconButton>
                    <IconButton href='mailto:info@ciscientific.com' className='main-banner-button-mobile'><EmailRoundedIcon className='main-banner-button-mobile-icon'/></IconButton>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
};

export default Banner;