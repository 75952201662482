import React from 'react'

export default function LoyaltyProgram() {
  return (
    <div className='loyalty-program-container'>
        <h2 className='loyalty-program-heading'>LOYALTY PROGRAM TERMS & CONDITION</h2>
        <p className='loyalty-program-subheading'>The loyalty program of The CI Group of Companies will be a way of rewarding their customers when they purchase, hire an equipment or book for services. It is a channel to strengthen customer relationships as well as to generate new buyers to loyal customers. The loyalty program is applicable to Autoclave Australia, Active Instrument Services, The CI Group of Companies and Kontrols & Industrial Weighing customers.</p>

        <div className='loyalty-program-div'>
            <p className='loyalty-program-item'>1. Introduction</p>
            <ul className='loyalty-program-description'>
                <li>1.1. These Terms and Conditions form the basis of The CI Group of Companies Loyalty Program. They are intended to protect both Members and the CI Group. It is the Member's responsibility to read and understand them. When earning and using The CI Group of Companies Benefits, Members will be responsible for ensuring their Account Managers, Account Users and Benefit Earners comply with these Terms and Conditions.</li>

                <li>1.2. These Terms and Conditions are effective upon signing up for the program and may be amended by The CI Group of Companies from time to time. The current Terms and Conditions are those available at this website. Members will be provided with a copy of the Terms and Conditions through our website www.ciscientific.com and material changes will be notified to Members in accordance with clause 3.2.</li>

                <li>1.3. Every Member is bound by these Terms and Conditions.</li>
            </ul>
        </div>
        <div className='loyalty-program-div'>
            <p className='loyalty-program-item'>2. Membership & Eligibility</p>
            <ul className='loyalty-program-description'>
                <li>
                2.1. The CI Group of Companies Loyalty Program (“Program”) is a rewards program offered by Autoclave Australia, Active Instrument Services, CI Scientific and Kontrols &Industrial Weighing to their existing and new customers.
                </li>
    
                <li>2.2. Membership to the Program (“Membership”) is limited to individuals only and is limited to one account per individual/company.</li>
    
                <li>2.3. You must be age 18 years or older to avail the membership. For Business owners, you must be registered legally with an existing and valid Australian Business Number.</li>
    
                <li>2.4. By registering in the Program, you agree to be bound by these terms and conditions (“Terms”).</li>
    
                <li>2.5. Registration is free. Successful registration will be assigned a Membership Username and Password and will receive 20 points as a welcome gift. It is the Member's responsibility to ensure that their Password and other security information are kept secure.</li>
            </ul>
        </div>
        <div className='loyalty-program-div'>
            <p className='loyalty-program-item'>3. Changes to The CI Group of Companies Loyalty Program</p>
            <ul className='loyalty-program-description'>
                <li>3.1. Subject to clause 3.2, The CI Group of Companies reserves the right to make any changes (whether material or otherwise) to members, the Terms and Conditions, the PDS, the Rewards or Benefits offered including:</li>

                <ul className='loyalty-program-description'>
                <li>3.1.1. Changes to:</li>
    
                <li>3.1.1.1. The ways in which Points are earned and redeemed;</li>
    
                <li>3.1.1.2. Points earning and redeeming rates;</li>
    
                <li>3.1.1.3. Limitations on the number of Rewards and Rewards redeemable on purchases;</li>
    
                <li>3.1.1.4. Rewards or Benefits, including the continued availability of Rewards or Benefits;</li>
    
                <li>3.1.1.5. Reward restrictions or conditions;</li>
    
                <li>3.1.1.6. Status Credit restrictions and conditions;</li>
    
                <li>3.1.1.7. membership level requirements and benefits</li>
    
                <li>3.1.1.8. the expiry of accrued Loyalty Points;</li>
                </ul>

                <li> 3.2 The CI Group of Companies will use best efforts to advise Members of material changes to these Terms and Conditions and, where such changes will limit Benefits.</li>
            </ul>
        </div>
        <div className='loyalty-program-div'>
            <p className='loyalty-program-item'>4. Termination or Suspension of Membership</p>
            <ul className='loyalty-program-description'>
                <li>4.1. If for any reason the The CI Group of Companies Loyalty Program is not capable of running as planned (including in the event of fraud or attempted fraud), The CI Group of Companies reserves the right in its absolute discretion to modify the terms of The CI Group of Companies Loyalty Program including the rewards and these Terms, or to suspend or terminate the The CI Group of Companies Loyalty Program.</li>

                <li>4.2. In the event of such modification from clause 4.1, suspension or termination, The CI Group of Companies will notify the members of The CI Group of Companies Loyalty Program via email, as well as by communicating this on our website.</li>

                <li>4.3. Members will not receive any compensation from The CI Group of Companies in the event of modification or suspension of The CI Group of Companies Loyalty Program.</li>

                <li>4.4. Any abuse of Program, failure to follow any terms of the Program, Membership inactivity for more than 12 months, any misrepresentation or any conduct detrimental to the interests of The CI Group of Companies, or fraud or attempted fraud, may subject members to Membership revocation and will affect eligibility for further participation in the Program.</li>

                <li>4.5. Membership is non-transferable and Membership purchases must be made by the member or has the consent of the member.</li>

                <li>4.6. If your Membership is revoked or otherwise canceled (whether by you or The CI Group of Companies in accordance with these Terms), or if the Program is terminated, any Points in your account will automatically expire.</li>

                <li>4.7. The CI Group of Companies will notify you via website or email prior to making changes to these Terms. It is your responsibility to check or review these Terms from time to time to keep informed of any changes.</li>

                <li>4.8. In the event that the Terms of the Program change, you may choose to cancel your Membership through your Membership profile or by sending an email to <span>info@aa.ciscientific.com</span></li>

                <li>4.9. Each Member must advise The <span>CI Group of Companies</span> of any change of name, address, or other details as soon as practicable after the change. Changes to the mailing address, PIN or other details may be made directly on www.ciscal.com.au or by contacting our <span>hotline 1300 225 542.</span> Members must supply their Membership Username, email address and any requested security information when making any such changes.</li>
                
                <li>Written proof must be supplied for any change of name and may be required for other changes. The <span>CI Group of Companies</span> is not responsible for any failure by a Member to notify The <span>CI Group of Companies</span> of any changes in accordance with this clause, or for any incorrect changes notified to The <span>CI Group of Companies.</span></li>
            </ul>
        </div>
        <div className='loyalty-program-div'>
            <p className='loyalty-program-item'>5. Personal Information</p>
            <ul className='loyalty-program-description'>
                <li>5.1. Each Member acknowledges that The <span>CI Group of Companies</span> collects, uses and discloses information about the Member, in order for The <span>CI Group of Companies</span> to provide the Member with the Benefits and administer The <span>CI Group of Companies</span> Loyalty Program and to undertake the activities further set out in this clause. The information about such use in this clause 5 should be read alongside the Terms and Condition.</li>

                <li>5.2. If the Member does not provide all or any part of the requested information, the services provided to that Member by The CI Group of Companies, and the Benefits that the Member receives, may be affected</li>

                <li>5.3. The <span>CI Group of Companies</span> collects personal information about each Member (including products and services available to The <span>CI Group of Companies Loyalty Program</span> members):</li>

                <ul className='loyalty-program-description'>
                    <li>5.3.1. To enable The <span>CI Group of Companies</span> to market products and services to the Member, including the products and services of The <span>CI Group of Companies</span> partners and other third parties who The CI Group of Companies believes may have products and services in which the Member would be interested. This is one of the primary purposes of The <span>CI Group of Companies Loyalty Program</span>;</li>

                    <li>5.3.2. to facilitate the Member's participation in The <span>CI Group of Companies</span>, including:</li>

                    <ul className='loyalty-program-description'>
                        <li>5.3.2.1. by assessing the Member's membership application,</li>
                        <li>5.3.2.2. awarding the Member with Points,</li>
                        <li>5.3.2.3. confirming the Member's eligibility for, and providing any products and services associated with, The <span>CI Group of Companies Loyalty Program</span>, and</li>
                        <li>5.3.2.4. enhancing and tailoring the Member's use of The <span>CI Group of Companies Loyalty Program</span>;</li>
                    </ul>

                    <li>5.3.3. for The <span>CI Group of Companies Loyalty Program</span> to generate consumer insights about the Member in order to better understand the Member's preferences and interests, tailor the Member's experience, enhance the products and services supplied to the Member and to tell the Member about the products and services of The <span>CI Group of Companies</span>.</li>

                    <li>5.3.4. to improve The CI Group of Companies website and services, including by means of product development and market and behavioral research; and</li>

                    <li>5.3.5. for operational management of The CI Group of Companies Loyalty Program.</li>
                </ul>

                <li>5.4. The <span>CI Group of Companies</span> may collect the Member's personal information from the Member directly, including when the Member is logged into their account.</li>

                <li>5.5. Marketing consent: By becoming a Member, the Member provides their express consent to The <span>CI Group of Companies Loyalty Program</span>:</li>

                <li>5.6. sending the Member marketing communications including via email, including:</li>
                <ul className='loyalty-program-description'>
                    <li>5.6.1. news and offers for The <span>CI Group of Companies Loyalty Program Members</span>;</li>

                    <li>5.6.2. offers, news and updates from The <span>CI Group of Companies Loyalty Program</span>;</li>

                    <li>5.6.3. promos, holiday specials, news and updates;</li>
                </ul>

                <li>5.7. The Member can opt out of receiving all or any of these communications:</li>
                    <ul className='loyalty-program-description'>
                        <li>5.7.1. for email and digital advertising by updating their email preferences in Your Profile;</li>
                        <li>5.7.2. Opting out of these communications will impact The <span>CI Group of Companies'</span> ability to provide the Member with all the Benefits that would otherwise be available to the Member as part of Membership.</li>
                    </ul>
            </ul>
        </div>
        <div className='loyalty-program-div'>
            <p className='loyalty-program-item'>6. Privacy Policy</p>
            <ul className='loyalty-program-description'>
                <li>6.1. The <span>CI Group of Companies</span> is committed to providing quality services to you and this policy outlines our ongoing obligations to you in respect of how we manage your Personal Information.</li>

                <li>6.2. We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way in which we collect, use, disclose, store, secure and dispose of your Personal Information.</li>

                <li>6.3. A copy of the Australian Privacy Principles may be obtained from the website of The Office of the Australian Information Commissioner at www.aoic.gov.au</li>

                <li>6.4. What is Personal Information and why do we collect it?</li>
                <ul className='loyalty-program-description'>
                    <li>6.4.1. Personal Information is information or an opinion that identifies an individual. Examples of Personal Information we collect include names, addresses, email addresses, phone and facsimile numbers.</li>

                    <li>6.4.2. This Personal Information is obtained in many ways including telephone and facsimile, by email, via our website www.ciscientific.com, from media and publications, from other publicly available sources, from cookies and from third parties. We don't guarantee website links or policy of authorised third parties.</li>

                    <li>6.4.3. We collect your Personal Information for the primary purpose of providing our services to you, providing information to our clients and marketing. We may also use your Personal Information for secondary purposes closely related to the primary purpose, in circumstances where you would reasonably expect such use or disclosure. You may unsubscribe from our mailing/marketing lists at any time by contacting us in writing.</li>

                    <li>6.4.4. When we collect Personal Information we will, where appropriate and where possible, explain to you why we are collecting the information and how we plan to use it.</li>
                </ul>

            <li>6.5. Sensitive Information</li>
            <ul className='loyalty-program-description'>
                <li>6.5.1. Sensitive information is defined in the Privacy Act to include information or opinion about such things as an individual's racial or ethnic origin, political opinions, membership of a political association, religious or philosophical beliefs, membership of a trade union or other professional body, criminal record or health information.</li>

                <li>6.5.2. Sensitive information will be used by us only:</li>

                <li>6.5.3. For the primary purpose for which it was obtained</li>

                <li>6.5.4. For a secondary purpose that is directly related to the primary purpose</li>

                <li>6.5.5. With your consent; or where required or authorised by law.</li>
            </ul>
            <li>6.6. Third Parties</li>

            <li>6.7. Where reasonable and practicable to do so, we will collect your Personal Information only from you. However, in some circumstances we may be provided with information by third parties. In such a case we will take reasonable steps to ensure that you are made aware of the information provided to us by the third party.</li>

            <li>6.8. Disclosure of Personal Information</li>
            <ul className='loyalty-program-description'>
                <li>6.8.1. Your Personal Information may be disclosed in several circumstances including the following:</li>

                <li>6.8.1.1. Third parties where you consent to the use or disclosure; and</li>

                <li>6.8.1.2. Where required or authorised by law.</li>
            </ul>
            <li>6.9. Security of Personal Information</li>
            <ul className='loyalty-program-description'>
                6.9.1. Your Personal Information is stored in a manner that reasonably protects it from misuse and loss and from unauthorized access, modification or disclosure.

                6.9.2. When your Personal Information is no longer needed for the purpose for which it was obtained, we will take reasonable steps to destroy or permanently de-identify your Personal Information. However, most of the Personal Information is or will be stored in client files which will be kept by us for a minimum of 7 years.
            </ul>
            6.10. Access to your Personal Information
            <ul className='loyalty-program-description'>
                6.10.1. You may access the Personal Information we hold about you and to update and/or correct it, subject to certain exceptions. If you wish to access your Personal Information, please contact us in writing.

                6.10.2. The CI Group of Companies Pty Ltd will not charge any fee for your access request but may charge an administrative fee for providing a copy of your Personal Information.

                6.10.3. To protect your Personal Information, we may require identification from you before releasing the requested information.
            </ul>

            6.11. Maintaining the Quality of your Personal Information
            <ul className='loyalty-program-description'>
                6.11.1. It is an important to us that your Personal Information is up to date. We will take reasonable steps to make sure that your Personal Information is accurate, complete and up-to-date. If you find that the information we have is not up to date or is inaccurate, please advise us as soon as practicable so we can update our records and ensure we can continue to provide quality services to you.
            </ul>

            6.12. Policy Updates
            <ul className='loyalty-program-description'>
                6.12.1. This Policy may change from time to time and is available on our website.
            </ul>
            </ul>
        </div>
        <div className='loyalty-program-div'>
            <p className='loyalty-program-item'>7. Limitation of liability</p>
            <ul className='loyalty-program-description'>
                <li>7.1. To the maximum extent permitted by law, we are not responsible for any loss, damage or expense, howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent suffered by you or any third party, arising from or in connection with your use of our Site and/or the Content and/or any inaccessibility of, interruption to or outage of our Site and/or any loss or corruption of data and/or the fact that the Content is incorrect, incomplete or out-of-date.</li>
            </ul>
        </div>
        <div className='loyalty-program-div'>
            <p className='loyalty-program-item'>8. Indemnity</p>
            <ul className='loyalty-program-description'>
                <li>8.1. To the maximum extent permitted by law, you must indemnify us, and hold us harmless, against any Liability suffered or incurred by us arising from or in connection with your use of our Site or any breach of these Terms or any applicable laws by you. This indemnity is a continuing obligation, independent from the other obligations under these Terms, and continues after these Terms end. It is not necessary for us to suffer or incur any Liability before enforcing a right of indemnity under these Terms.</li>
            </ul>
        </div>
        <div className='loyalty-program-div'>
            <p className='loyalty-program-item'>9. Termination</p>
            <ul className='loyalty-program-description'>
                <li>9.1. These Terms are effective until terminated by us, which we may do at any time and without notice to you. In the event of termination, all restrictions imposed on you by these Terms and limitations of liability set out in these Terms will survive.</li>
            </ul>
        </div>
        <div className='loyalty-program-div'>
        <p className='loyalty-program-item'>10. Disputes</p>
            <ul className='loyalty-program-description'>
                <li>10.1. In the event of any dispute arising from, or in connection with, these Terms (Dispute), the party claiming there is a Dispute must give written notice to the other party setting out the details of the Dispute and proposing a resolution. Within 14 days after receiving the notice, the parties must, by their senior executives or senior managers (who have the authority to reach a resolution on behalf of the party), meet at least once to attempt to resolve the Dispute or agree on the method of resolving the Dispute by other means, in good faith. All aspects of every such conference, except the fact of the occurrence of the conference, will be privileged. If the parties do not resolve the Dispute, or (if the Dispute is not resolved) agree on an alternate method to resolve the Dispute, within 21 days after receipt of the notice, the Dispute may be referred by either party (by notice in writing to the other party) to NCAT for mediation.</li>
            </ul>
        </div>
        <div className='loyalty-program-div'>
        <p className='loyalty-program-item'>11. Severance</p>
            <ul className='loyalty-program-description'>
                <li>11.1. If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision must be read down as narrowly as necessary to allow it to be valid or enforceable. If it is not possible to read down a provision (in whole or in part), that provision (or that part of that provision) is severed from these Terms without affecting the validity or enforceability of the remainder of that provision or the other provisions in these Terms.</li>
            </ul>
        </div>
        <div className='loyalty-program-div'>
        <p className='loyalty-program-item'>12. Jurisdiction</p>
            <ul className='loyalty-program-description'>
                <li>12.1. Your use of our Site and these Terms are governed by the laws of New South Wales. You irrevocably and unconditionally submit to the exclusive jurisdiction of the courts operating New South Wales and any courts entitled to hear appeals from those courts and waive any right to object to proceedings being brought in those courts.</li>
                <li>12.2. Our Site may be accessed throughout Australia and overseas. We make no representation that our Site complies with the laws (including intellectual property laws) of any country outside Australia. If you access our Site from outside Australia, you do so at your own risk and are responsible for complying with the laws of the jurisdiction where you access our Site.</li>
            </ul>
        </div>
        <div className='loyalty-program-div'>
        <p className='loyalty-program-item'>13. Membership, Status Credits and Benefits</p>
            <ul className='loyalty-program-description'>
                <li>13.1. Registration is free and members are eligible to use their credits/points to their online or offline purchases, hiring and services of equipment.</li>
                <li>13.2. To collect points through CI Scientific website, members should log in their account when purchasing online. Failure to log in before or while purchasing will not incur any points. The <span>CI Group of Companies</span> will not be held liable for any failure on adhering to the rules and regulation of the The <span>CI Group of Companies Loyalty Program</span></li>
                <li>13.3. To collect points offline, the Member must inform the representative of The <span>CI Group of Companies</span> their username or email address via email or call when they purchase through any representative of The CI Group of Companies.</li>
                <ul className='loyalty-program-description'>
                    <li>13.3.1. Member should provide the same username/ email address they used to register for the program.</li>
                    <li>13.3.2. If the representative was not able to find the given username/email address that was provided by the member then the representative from The <span>CI Group of Companie</span>s will consider it as a new registration.</li>
                </ul>
                <li>13.4. Member must spend a minimum of $100, excluding tax and freight charge, quarterly or every 3 months to retain their membership in the The <span>CI Group of Companies Loyalty Program</span></li>
                <li>13.5. Members will receive 1 point every $100 worth made in one receipt/ invoice, excluding tax and freight charge.</li>
                <li>13.6. The Maximum amount a member could earn in one receipt/invoice is 100 points even if the receipt/ invoice exceeds $10,000 worth of purchased goods/ booked service/ hired equipment.</li>
                <li>13.7. Earned points will be added to the members account in 24-48 hours after the date the goods are purchased/ hired, or the service was scheduled. Points cannot be redeemed until added to your Loyalty Account.</li>
                <li>13.8. No reward points will be given for purchases where coupons, vouchers and points are used.</li>
                <li>13.9. Points can only be used in one transaction.</li>
                <li>13.10. The <span>CI Group of Companies</span> are entitled to cancel points awarded if the relevant products to which such award relates are returned for any reason and a refund of the purchase price is given. If the relevant products are exchanged for other products, the points awarded would be adjusted upwards or downwards depending on whether the exchange is for a higher or lower value item.</li>
                <li>13.11. Points cannot be used on top of other promotions. Points earned are computed based on total amount paid after all the discounts are applied, if a purchase was made during an ongoing promo.</li>
                <li>13.12. The CI Group of Companies has the right to cancel the redemption of points if a purchase was made while there is an ongoing promotion. Cancelled redemption points will then be returned to the members account.</li>
                <li>13.13. Members of The <span>CI Group of Companies Loyalty Program</span> can only use 1 point per $10 amount of purchase/ equipment hire or booked service.</li>
                <li>13.14. Members of The <span>CI Group of Companies Loyalty Program</span> will be able to enjoy these benefits:</li>
                <ul className='loyalty-program-description'>
                    <li>13.14.1. Member can deduct their accumulative points to</li>
                    <ul className='loyalty-program-description'>
                        <li>13.14.1.1. their purchases online of consumables</li>
                        <li>13.14.1.2. hire equipment online/offline</li>
                        <li>13.14.1.3. purchase equipment online/offline</li>
                        <li>13.14.1.4. book calibration services online/offline</li>
                </ul>
                </ul>

            </ul>
        </div>

        <p className='loyalty-program-description'>If you have any queries or complaints about our Website Usage Terms and Conditions & Privacy Policy, please contact us at:</p>
        <p className='loyalty-program-description'><span>The CI Group of Companies Pty Ltd</span><br/>
        Unit 1/9 Bearing Rd Seven Hills 2147 NSW<br/>
        info@aa.ciscientific.com<br/>
        <span>1300-225-542</span></p>
        
    </div>
  )
}
