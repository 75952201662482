import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import headerImage from '../website-images/OTHER GAS.jpg';

const OtherGasFillingSystemsSolutions = (props) => {
    return (
        <div className='solutions-item-container-right' data-aos="fade-up">
            <div className='solutions-item-header-right'>
                <div className='solutions-item-image-container-right'>
                    <img src={headerImage} alt='lpg filling systems' className='solutions-item-image-right'/>
                </div>
            </div>
            <div className='solutions-item-body-right'>
                <div className='solutions-item-texts-right'>
                    <h2>Other Gas Filling System</h2>
                    <p>
                        Gas Fill Australia offers a wide variety of gas filling solutions and products that provide a significant advantages for our customers. 
                        We offer services and solutions from Gas Blending, Nail Gun Recharging, to Oxygen Filling Solutions and much more. All solutions are following strict standards 
                        and compliant to Australian Government Certifications.
                    </p>
                </div>
                <div className='solutions-item-actions-right'>
                <Link to='/solutions/other-filling-systems' className='solution-button-link'>
                    <Button
                        className='check-solutions-right'
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={props.eventTrack.bind(this, "solutions_page_buttons", "button_clicks", "other_checkthisout_button")}
                        >
                        Check This Out
                    </Button>
                </Link>
                </div>
            </div>
        </div>
    );
};

export default OtherGasFillingSystemsSolutions;