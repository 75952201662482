import React from 'react';

import WIA from '../website-images/Weighing Industry Association of Australia.png';
import NATA from '../website-images/NATA.png';
import ASMI from '../website-images/Australian Government - National Measurement Institute.png';

const Accreditations = () => {
    return (
        <div className='accreditations-container' data-aos="fade-down">
            <h2 className='title-header'>Accreditations</h2>
            <div className='accreditation-images'>
                <img src={WIA} alt='Weighing Industry of Australia' className='accreditation-image'/>
                <img src={NATA} alt='Nata' className='accreditation-image'/>
                <img src={ASMI} alt='Australian Government National Measurement Institute' className='accreditation-image'/>
            </div>
        </div>
    );
};

export default Accreditations;