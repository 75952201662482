import React, { useReducer } from 'react';
import formReducer from '../reducers/FormReducer';

import NewsLetterModal from './NewsLetterModal';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import FacebookIcon from '../website-images/facebook-icon.svg';
import InstagramIcon from '../website-images/instagram-icon.svg';
import YoutubeIcon from '../website-images/youtube-icon.svg';
import LinkedinIcon from '../website-images/linked-in-icon.svg';


const initialNewsLetterState = {
    emailAddress: ""
};

const NewsLetter = (props) => {

    const [state, dispatch] = useReducer(formReducer, initialNewsLetterState);
    const [modalShow, setModalShow] = React.useState(false);

    const handleTextChange = (event) => {
        dispatch({
            type: "HANDLE NEWSLETTER INPUT",
            field: event.target.name,
            payload: event.target.value,
        });
    };

    return (
        <div className='news-letter-container'>
            <div className='newsletter-desktop-layout'>
                
                <div className='news-letter-header'>
                    <h2 className='title-header' data-aos="fade-right">We Want to hear from you!</h2>
                </div>

                <div className='newsletter-column-right' data-aos="fade-right">
                    <div className='newsletter-form'>
                            <TextField 
                                type="email"
                                name="emailAddress"
                                value={state.emailAddress}
                                id="outlined-basic" 
                                label="Enter email address" 
                                variant="filled" 
                                margin="dense"
                                sx={{backgroundColor: '#FFFFFF', width: "55%"}}
                                required
                                className='newsletter-field'
                                onChange={handleTextChange}
                            />

                            <Button
                                className='request-btn'
                                variant="outlined"
                                size='large'
                                color="primary"
                                name='submit' 
                                value='submitRequest'
                                type='submit'
                                onClick={() => setModalShow(true)}
                            >
                                Join our Mailing List
                            </Button>
                    </div>

                    <div className='social-channels-container' data-aos="fade-right">
                        <p>You can connect with us through the following channels:</p>

                        <div className='social-icons-container'>
                            <IconButton className='newsletter-icons' href='https://www.facebook.com/ciscientific/' target='_blank' rel='noreferrer'><img src={FacebookIcon} alt='facebook' className='newsletter-socials' onClick={props.eventTrack.bind(this, "newsletter_section", "button_clicks", "homepage_newsletter_section_facebook_link")} /></IconButton>
                            <IconButton className='newsletter-icons' href='https://www.instagram.com/ci_scientific/' target='_blank' rel='noreferrer'><img src={InstagramIcon} alt='instagram' className='newsletter-socials' onClick={props.eventTrack.bind(this, "newsletter_section", "button_clicks", "homepage_newsletter_section_instagram_link")} /></IconButton>
                            <IconButton className='newsletter-icons' href='https://www.youtube.com/user/CIScientific' target='_blank' rel='noreferrer'><img src={YoutubeIcon} alt='youtube' className='newsletter-socials' onClick={props.eventTrack.bind(this, "newsletter_section", "button_clicks", "homepage_newsletter_section_youtube_link")} /></IconButton>
                            <IconButton className='newsletter-icons' href='https://www.linkedin.com/company/ci-scientific/' target='_blank' rel='noreferrer'><img src={LinkedinIcon} alt='linkedin' className='newsletter-socials' onClick={props.eventTrack.bind(this, "newsletter_section", "button_clicks", "homepage_newsletter_section_linkedin_link")} /></IconButton>
                        </div>
                    </div>
                </div>

                
            </div>

            

            <div className='newsletter-tagline' data-aos="fade-up">
                <p className='title-header' id='tagline'>The future of calibration</p>
            </div>

            <NewsLetterModal 
                show={modalShow}
                onHide={() => setModalShow(false)}
                //handleFormSubmit = {handleFormSubmit}
                emailAddress = {state.emailAddress}
                notifyDuplicateNewsLetterRecord={props.notifyDuplicateNewsLetterRecord}
                notifyNewsLetterSuccess={props.notifyNewsLetterSuccess}
                eventTrack={props.eventTrack}
            />

        </div>
    );
};

export default NewsLetter;