import React, { useReducer } from 'react';
import axios from 'axios';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import formReducer from '../reducers/FormReducer';

let initialContactFormState = {
    companyName: "",
    contactPerson: "",
    contactNumber: "",
    emailAddress: "",
    message: "",
};

const ContactForm = (props) => {
    let [state, dispatch] = useReducer(formReducer, initialContactFormState);

    const handleTextChange = (event) => {
        dispatch({
            type: "HANDLE CONTACT FORM INPUT",
            field: event.target.name,
            payload: event.target.value,
        });
    };

    const handleFormSubmit = (event) => {
        let data = state;
        //console.log(data);
        sendContactForm(data);
        event.preventDefault();
    }

    const sendContactForm = (data) => {
        axios.post(`${process.env.REACT_APP_MAILER_API_URL}/gas-fill-contact-form`, data)
        .then(response => {
            let result = response.data;
            if (result.status === "Success") {
                console.log('Email Sent!');
                props.notifyToastSuccess();
                formReset();
            }else {
                console.log("Error");
                props.notifyToastFailed();
            }
            //console.log(result.data);
        });
    }

     // RESET FORM FUNCTION
     const formReset = () =>{
        dispatch({type: "HANDLE FORM RESET"});
    }


    return (
        <div className='contact-form-container' data-aos='fade-up'>
            <h2 className='title-header' data-aos="flip-down">Get in touch?</h2>
            <p className='title-header-paragraph' data-aos="zoom-in">
                Get in touch with us by filling out the form below. 
                Our specialists will contact you for the service you need. 
            </p>

            <form className='request-form' method='POST' onSubmit={handleFormSubmit} data-aos="zoom-in-right">
                <div className='form-container'>
                    <div id='form-column-left'>
                        <TextField
                        type="text"
                        name="companyName"
                        value={state.companyName}
                        className='contact-form-field' 
                        id="outlined-basic" 
                        label="Company Name" 
                        variant="filled" 
                        margin="dense"
                        sx={{backgroundColor: '#FFFFFF', border: '1'}}
                        required
                        onChange={handleTextChange}
                        />

                        <TextField
                        type="text"
                        name="contactPerson"
                        value={state.contactPerson}
                        className='contact-form-field' 
                        id="outlined-basic" 
                        label="Contact Person" 
                        variant="filled" 
                        margin="dense"
                        sx={{backgroundColor: '#FFFFFF', border: '1'}}
                        required
                        onChange={handleTextChange}
                        />

                        <TextField
                        type="number"
                        name="contactNumber"
                        value={state.contactNumber}
                        className='contact-form-field' 
                        id="outlined-basic" 
                        label="Contact Number" 
                        variant="filled" 
                        margin="dense"
                        sx={{backgroundColor: '#FFFFFF', border: '1'}}
                        required
                        onChange={handleTextChange}
                        />

                        <TextField
                        type='email'
                        name="emailAddress"
                        value={state.emailAddress}
                        className='contact-form-field' 
                        id="outlined-basic" 
                        label="Email Address" 
                        variant="filled" 
                        margin="dense"
                        sx={{backgroundColor: '#FFFFFF', border: '1'}}
                        required
                        onChange={handleTextChange}
                        />
                    </div>

                    <div id='form-column-right'>
                        <TextField
                        type='text'
                        name="message"
                        value={state.message}
                        className='contact-form-field' 
                        id="outlined-basic message-box" 
                        label="Message" 
                        variant="filled" 
                        multiline
                        rows={9.9}
                        margin="dense"
                        sx={{backgroundColor: '#FFFFFF', border: '1'}}
                        onChange={handleTextChange}
                        />
                    </div>
                    
                </div>

                <Button
                    className='request-btn'
                    variant="outlined"
                    color="primary"
                    size="large"
                    name='submit' 
                    value='submitRequest'
                    type='submit'
                    data-aos="zoom-in-right"
                    /**handles the Google Analytics triggering when the form field (companyName, contactPerson, emailAddress, contactNumber) are all filled out*/
                    onClick={
                        state.companyName.length === 0 ? null
                        : state.contactPerson.length === 0 ? null 
                        : state.emailAddress.length === 0 ? null 
                        : state.contactNumber.length === 0 ? null 
                        : props.eventTrack.bind(this, "contact_us_homepage_form_submissions", "forms_submissions", "contact_form")
                    }
                    >
                    Submit
                </Button>

            </form>

        </div>
    );
};

export default ContactForm;