import React from 'react';

const IntroSection = () => {
    return (
        <div className='intro-section-main-container'>
            <div className="intro-section-text-container">
                <h2 className="intro-section-tagline" data-aos="fade-right"><span className="yellow-text" data-aos="fade-up">Experience efficiency and reliability</span> <span data-aos="fade-down">with our top-quality gas filling solutions.</span></h2>
                <p className="intro-section-texts" data-aos="fade-right">
                    Gas Filling Solutions are an essential component for various industries, providing a reliable and cost-effective way to 
                    power their operations. Gas Fill Australia is a leading provider of gas filling solutions, offering a range of products such 
                    as LPG filling solutions, Acetylene filling solutions, and more. By using Gas Fill Australia's gas filling solutions, 
                    businesses can reduce downtime, increase productivity, and ultimately save money. With a focus on safety and efficiency, 
                    Gas Fill Australia is the go-to choice for gas filling solutions that businesses can rely on.
                </p>
            </div>
        </div>
    );
};

export default IntroSection;