import React from 'react';

const BusinessStats = () => {
    return (
        <section className='business-stats-main-container'>
            <div className="business-stats-item-container">
                <div className="business-stats-item">
                    <h4 className="business-stats-number-header" data-aos="fade-down">+60</h4>
                    <p className="business-stats-text" data-aos="fade-up-right">years of experience</p>
                </div>

                <div className="business-stats-item">
                    <h4 className="business-stats-number-header" data-aos="fade-down">12</h4>
                    <p className="business-stats-text" data-aos="fade-up-right">countries with developed projects</p>
                </div>

                <div className="business-stats-item">
                    <h4 className="business-stats-number-header" data-aos="fade-down">+1000</h4>
                    <p className="business-stats-text" data-aos="fade-up-right">satisfied customers</p>
                </div>

                <div className="business-stats-item">
                    <h4 className="business-stats-number-header" data-aos="fade-down">100</h4>
                    <p className="business-stats-text" data-aos="fade-up-right">projects in progress</p>
                </div>
            </div>
        </section>
    );
};

export default BusinessStats;