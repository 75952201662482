import { useEffect } from "react";
import { useLocation } from "react-router-dom";

//handles the scroll to top behavior when navigating to other pages.
const PageReloader = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        if(!window.location.hash) {
            window.location = window.location + '#page-view';
            window.location.reload();
        }
      }, [pathname]);
    
      return null;
};

export default PageReloader;