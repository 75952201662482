import React, {  } from 'react';
//import React, { useState } from 'react';
import { Link } from 'react-router-dom';


import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Button from 'react-bootstrap/Button';
//import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// IMAGES
import valueForMoneyIcon from '../website-images/value-for-money-icon.svg';
import systemProgramIcon from '../website-images/system-program-icon.svg';
import easeOfUse from '../website-images/ease-of-use-icon.svg';
import upgradesAndTradeInsIcon from '../website-images/upgrades-and-trade-ins-icon.svg';
import payAsYouUseIcon from '../website-images/pay-as-you-use-icon.svg';
import informationIcon from '../website-images/information-icon.svg';
import gasCylinderStorage from '../website-images/Gas Cylinder Storage.jpg';
import CO2 from '../website-images/CO2.png';
import otherGas from '../website-images/OTHER GAS.jpg';

// MODELS & SPECS TABLE COMPONENTS
//import LPGEX1Features from '../components/LPGEx-1Features';
//import LPGEx2Features from '../components/LPGEx-2Features';
//import LPGM4MAXXFeatures from '../components/LPGM4-MAXXFeatures';

const LPGFillingSystems = (props) => {

    const images = [

        {
        original: '../website-images/LPG.jpg',
        thumbnail: '../website-images/LPG.jpg',
        originalWidth: 1024,
        thumbnailWidth: 100
        },
    ]

    // REQUEST A QUOTE MODAL HANDLER
    const HandleOpenModal = () => {
        props.openedModal(true);

        // Save product title to local storage
        let value = document.getElementById('LPG-title-id').innerText;
        localStorage.setItem('productTitle', value)
        console.log(value);
    };



  return (
    <div className='LPG-Filling-container'>
        {/* INFO SECTION */}
        <div className='LPG-info-div container'>
            <div className='row'>
            <div className='carousel-div col col-12 col-lg-6'>
                <ImageGallery items={images} />
            </div>
            <div className='col col-12 col-lg-6'>
                <h2 className='LPG-info-title' id='LPG-title-id'>LPG FILLING SYSTEMS</h2>
                <p>Gas Fill Australia are designed to meet Australia's toughest and harshest conditions, while also maintaining compliance to the requirements of the gas industry</p>
                <p>Our LPG systems provide significant advantages for our customers. Working on the proven Platforms of the earlier designs of the system,we have upgraded the operating system to our new M4 MAX SERIES as well as the all-new EX SERIES. These systems provide you with the cost savings and added benefits of the latest technology, leaving the competition behind.</p>
                <p>When you purchase our LPG system it is engineered to work as part of wider system of machines or as a stand-alone system.  What ever your needwe have a solution for you.</p>

                <div className='LPG-button-div'>
                <Button className='LPG-request-quote' size='lg' onClick={HandleOpenModal}>REQUEST A QUOTE</Button>
                
                
                </div>
            </div>
            </div>
        </div>

        {/* ADVANTAGES SECTION */}
        <div className='LPG-Advantages-div'>
            <h2 className='LPG-advantages-title'>ADVANTAGES</h2>

            <div className='LPG-advantages-content container'>
            <div className='row  align-items-center'>

                <div className='col col-12 col-md-3 p-3 advantages-img-div'>
                    <img src={valueForMoneyIcon} className='advantages-img' alt='value-for-money'/>
                    <h4>VALUE FOR MONEY</h4>
                </div>
                <div className='col col-12 col-md-9 p-3'>
                    <p>Our LPG models are the cheapest to own over their life span. Our is proven to reduce gas wastage savings of over 15kg of Gas per 500kg filled. Using the highest quality parts from globally recognised brands ensures the longevity of our systems.</p>
                </div>

                <div className='col col-12 col-md-3 p-3 order-md-2'>
                    <img src={systemProgramIcon} className='advantages-img' alt='system-program'/>
                    <h4>SYSTEM PROGRAM</h4>
                </div>
                <div className='col col-12 col-md-9 p-3 order-md-1'>
                    <p>Gas Fill Australia has a specialised program that can be monitored from any control room providing management with the critical statistics of their output performance. Displays totalisations of gas filled in KG over a complete day, month, year, and the live time of your machine.</p>

                    <p>This system program is available through all our LPG systems.</p>
                </div>
                <div className='col col-12 col-md-3 p-3 order-md-3'>
                    <img src={easeOfUse} className='advantages-img' alt='ease-of-use'/>
                    <h4>EASE OF USE</h4>
                </div>
                <div className='col col-12 col-md-9 p-3 order-md-4'>
                    <p>Gas Fill Australia system is the easiest to use in the market. Limited training is required to use our system. It is an intuitive and informative system that allows an operator to toggle between cylinders, tare weights, and many more features by virtue of the tare dials.</p>
                    
                    <p>Not only this there is also well over 20 years of building these systems as well as our friendly team who are there to support our customers to ensure ease of use.</p>
                </div>

            </div>
            </div>

        </div>

        {/* MODELS & SPECIFICATIONS SECTION */}
        {/*
        <div className='LPG-models-specs-div'>
            <h2 className='LPG-models-specs-title'>MODELS AND SPECIFICATIONS</h2>
            <Accordion defaultActiveKey={['0']} alwaysOpen className='accordion-div'>
            <Accordion.Item eventKey="0" className='accordion-item'>
                <Accordion.Header className='accordion-header'>EX-1</Accordion.Header>
                <Accordion.Body className='accordion-body'>
                <LPGEX1Features />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className='accordion-item'>
                <Accordion.Header className='accordion-header'>EX-2</Accordion.Header>
                <Accordion.Body className='accordion-body'>
                <LPGEx2Features />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className='accordion-item'>
                <Accordion.Header className='accordion-header'>M4 MAXX</Accordion.Header>
                <Accordion.Body className='accordion-body'>
                <LPGM4MAXXFeatures />
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        </div>
         */}

        {/* OTHER INFO SECTION */}
        <div className='other-info-div container'>
            <div className='row'>
                <div className='col col-12 col-md-6'>
                    <img src={upgradesAndTradeInsIcon}  className='upgrades-and-tradeIns-icon' alt='upgrade'/>
                    <h3>UPGRADES & TRADE-INS</h3>

                    <div>
                    <p>
                        Do you have an older machine but not the money to upgrade? Gas Fill Australia have you covered.  
                        We are able to upgrade your old system to our newest systems for a fraction of  the cost of a new system. 
                        We have multiple upgrade options available that can either be done at one of our locations or have an upgrade kit sent to you for your local electrician to do.
                    </p>

                    <div className='info-cards'>
                        <img src={informationIcon} className='info-icon' alt='information icon'/>
                        <p>
                            If an upgrade is not something you are after, then you can also look at a trade in on your old system for our new system. Minimum amount 
                            received towards your new purchase is <span>$500</span> on the system.
                        </p>
                    </div>
                    </div>
                </div>
                <div className='col col-12 col-md-6'>
                    <img src={payAsYouUseIcon} className='pay-as-you-use-icon' alt='pay-as-you-can'/>
                    <h3>PAY AS YOU USE</h3>

                    <div>
                    <p>
                        If you are in the market for a reliable system but funds are tight Gas Fill Australia have a system for you. 
                        With our pay as you use system you are charged per cylinder filled. This means during those quiet times our systems costs nothing. 
                        Under this system you automatically gain access to our premium range of machines. This also includes your annual calibration costs and repairs for 
                        our machines. It also means every 5 years you get the benefit of a NEW Machine with no extra charges.  To get more information speak to one of our friendly team.
                    </p>

                    <div className='info-cards'>
                        <img src={informationIcon} className='info-icon' alt='information icon'/>
                        <p>You automatically get access to our premium machine M4 Max</p>
                    </div>
                </div>
                </div>
            </div>
        </div>

        {/* RELATED PRODUCTS SECTION */}
        <div className='related-products-div'>
            <h2>RELATED PRODUCTS</h2>
            <Container className='related-products-cards-container'>
            <Row className='related-products-cards row'>
            <Col sm={4}>
                <Card className="related-products-card text-white">
                <Card.Img src={CO2} alt="Card image" className='related-products-img'/>
                <Card.ImgOverlay className='related-products-overlay'>
                    <Card.Title className='related-products-overlay-title'>CO2 FILLING SYSTEM</Card.Title>
                    <Card.Text className='related-products-overlay-btn-div'>
                    <Link to="/solutions/co2-filling-systems"><Button className='related-products-overlay-btn'>VIEW PRODUCT</Button></Link>
                    </Card.Text>
                </Card.ImgOverlay>
                </Card>
            </Col>
            <Col sm={4}>
                <Card className="related-products-card text-white">
                <Card.Img src={gasCylinderStorage} alt="Card image" className='related-products-img'/>
                <Card.ImgOverlay className='related-products-overlay'>
                    <Card.Title className='related-products-overlay-title'>ACETYLENE GAS FILLING</Card.Title>
                    <Card.Text className='related-products-overlay-btn-div'>
                    <Link to="/solutions/acetylene-filling-systems"><Button className='related-products-overlay-btn'>VIEW PRODUCT</Button></Link>
                    </Card.Text>
                </Card.ImgOverlay>
                </Card>
            </Col>
            <Col sm={4}>
                <Card className=" related-products-card text-white">
                <Card.Img src={otherGas} alt="Card image" className='related-products-img'/>
                <Card.ImgOverlay className='related-products-overlay'>
                    <Card.Title className='related-products-overlay-title'>OTHER GAS FILLING</Card.Title>
                    <Card.Text className='related-products-overlay-btn-div'>
                    <Link to="/solutions/other-filling-systems"><Button className='related-products-overlay-btn'>VIEW PRODUCT</Button></Link>
                    </Card.Text>
                </Card.ImgOverlay>
                </Card>
            </Col>
            </Row>
            </Container>

        </div>
    </div>
  )
}
export default LPGFillingSystems;
