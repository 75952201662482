import React from 'react'

export default function ShippingAndReturns() {
  return (
    <div className='shipping-and-returns-container'>
      <h2 className='shipping-and-returns-heading'>SHIPPING & RETURNS</h2>
      <div className='shipping-and-returns-div'>
        <p className='returns-policy'>RETURNS POLICY</p>
        <p className='shipping-and-returns-description'>In the event of “out of box failure”, CI Scientific Pty Ltd (CIS) service department must be contacted on 1300 225 542 to report the fault. If directed by a CIS authorised representative, we will accept return of the sold item only if repacked in it's original packaging and showing no signs of use, shipping excluded. For other technical or product failures, please refer to the “warranty” section below.</p>
      </div>
      <div className='shipping-and-returns-div'>
        <p className='shipping'>SHIPPING</p>
        <p className='shipping-and-returns-description'>Shipping can be achieved to any Australian residential or company physical address (i.e. PO Box address are not accepted). For non-stock items, delivery may take up to 10-12 weeks from purchase date. Contact CI Scientific Pty Ltd CIS prior to completing your order if you wish to check availability of a specific item. CIS use both courier and postal services throughout Australia and delivery times and charges vary depending on size of item and customer location.</p>
      </div>
      <div className='shipping-and-returns-div'>
        <p className='warranty'>WARRANTY</p>
        <p className='shipping-and-returns-description'>CI Scientific Pty Ltd products have a reputation for reliability and long term performance and have warranty periods varying from 12 months to 5 years. In the unlikely event that your instrument does fail during our stated warranty period, CIS offer a “back-to-base” warranty where you (the client) are liable for the cost of returning the faulty unit to an Australian CIS office as instructed by the CIS representative. If you suspect a fault exists then contact the CIS Service Team for further instruction. Some faults may be correctable via telephone or email advice (e.g. software related issues). Other faults will require you to obtain a “Returns Number” from CIS Service Department and follow their instructions regarding re-packing the unit. Upon inspection by CIS, if the fault is determined to be a valid warranty claim, CIS will replace or repair the unit and will cover the shipping cost back to you. In the event the fault is not covered by the warranty, CIS will not be liable for your return freight costs and an inspection/quotation fee may be charged at CIS's discretion.</p>
      </div>
    </div>
  )
}
