import { React } from 'react';
import 'aos/dist/aos.css';

import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';

const CardsSection = () => {

    return (
        <section className='cards-section-main-container'>
            <div className="cards-section-header-text-container" data-aos="zoom-in">
                <h3 className="cards-section-header">Reliable and efficient service</h3>
                <p className="cards-section-texts">
                    Get peace of mind with our top-quality gas filling solutions.
                </p>
            </div>
            <div className="cards-section-cards">
                <div className="card" data-aos="zoom-in">
                    <div className="logo-container" data-aos="fade-right"><MarkEmailReadOutlinedIcon className='logo'/></div>
                    <div className="card-text-container">
                        <p className="card-text">
                            Reach out to us for inquiries or consultation about your desired gas filling solutions.
                        </p>
                    </div>
                </div>

                <div className="card" data-aos="zoom-in">
                    <div className="logo-container" data-aos="fade-right"><PersonPinOutlinedIcon className='logo' /></div>
                    <div className="card-text-container">
                        <p className="card-text">
                            Receive outstanding service and advice from our qualified technicians about the best gas filling solutions you need.
                        </p>
                    </div>
                </div>

                <div className="card" data-aos="zoom-in">
                    <div className="logo-container" data-aos="fade-right"><HandymanOutlinedIcon className='logo' data-aos="zoom-in" /></div>
                    <div className="card-text-container">
                        <p className="card-text">
                            Build a custom solution that is quality and  cost-effective based on the specification you provided.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CardsSection;