//INITIAL STATE
let initialState = {
    productName: "",
    companyName: "",
    contactPerson: "",
    emailAddress: "",
    contactNumber: "",
    message: "",
};

function FormReducer(state, action) {
    switch (action.type) {
        case "HANDLE CONTACT FORM INPUT":
            return {
                ...state,
                [action.field]: action.payload,
            };
        case "HANDLE NEWSLETTER INPUT":
            return {
                ...state,
                [action.field]: action.payload,
            };
        case "HANDLE NEWSLETTER INPUT2":
            return {
                ...state,
                [action.field]: action.payload,
            };
        case "HANDLE REQUEST QUOTE":
            return {
                ...state,
                [action.field]: action.payload,
            };
        case "HANDLE FORM RESET":
            return initialState;
        default:
            return state;
    }
}


export default FormReducer;