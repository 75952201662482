import { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import NavigationLogo from '../website-images/gas-fill-logo.svg';

function NavigationBar() {

  const [activeNavbar, setActiveNavbar] = useState(false); 
  const [whiteNavBar, setWhiteNavBar] = useState(true);
  const [iconChange, setIconChange] = useState(true);
  

  //used to extract the current path of the page.
  let pathLocation = useLocation();

  /*For Handling Navigation Bar change based on Scroll event. 
   * This is the default animation.**/
  useEffect(() => {
    window.addEventListener('scroll', navBarChange);

    function navBarChange() {
      if (window.scrollY >= 100) {
        setActiveNavbar(true);
      }else {
        setActiveNavbar(false);
      }
    };

    return function cleanup() {
      window.removeEventListener('scroll', navBarChange);
    }
  }, [activeNavbar]);
  
  //================================================================================

 /**For handling navigation animation for SOLUTIONS and CONTACT US page
  * white background if the pathname is "/solutions and /contact-us"
  * transparent if the pathname is "/"
  * ===================================================================
  * Also handles the nav-icon-change on mobile dimensions. 
  * white main-nav-icon on HOMEPAGE.
  * balck main-nav-icon on SOLUTIONS AND CONTACT US.
  */
  useEffect(() => {
    window.addEventListener('load', whiteNavigation);
    window.addEventListener('click', whiteNavigation);

    function whiteNavigation() {
       if (pathLocation.pathname === '/') {
        setWhiteNavBar(false);
        setIconChange(false);
       }else {
        setWhiteNavBar(true);
        setIconChange(true);
       }
    };

    return function cleanup() {
      window.removeEventListener('load', whiteNavigation);
      window.removeEventListener('click', whiteNavigation);
    }

  }, [whiteNavBar, pathLocation.pathname]);

  function whiteNavigation() {
    if (pathLocation.pathname === '/') {
     setWhiteNavBar(false);
     setIconChange(false);
    }else {
     setWhiteNavBar(true);
     setIconChange(true);
    }
 };

//================================================================================
  

  return (
    <div className='navigation-bar-container' id="navigation-bar">
      {['md'].map((expand) => (
        <Navbar 
          key={expand} 
          expand={expand} 
          className={ 
            activeNavbar ? 'mb-3 main-navigation-bar nav-active' : 
            whiteNavBar || iconChange ? 'mb-3 main-navigation-bar white-navbar nav-icon-change' :
            'mb-3 main-navigation-bar'
          } 
          collapseOnSelect='true' 
          id="main-navigation" 
          onLoad={whiteNavigation}
          onClick={whiteNavigation}>

          <Container 
            fluid 
            className="main-navigation-bar-container" >

            <Navbar.Brand 
            className='navigation-logo-container' 
            as={ Link } 
            to='/'>
              <img 
              src={NavigationLogo} 
              alt="main-nav-logo" 
              className='navigation-logo' />
            </Navbar.Brand>

            <Navbar.Toggle 
            aria-controls={`offcanvasNavbar-expand-${expand}`} 
            className='navigation-button' />
            
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="main-nav-bar-body" >

              <Offcanvas.Header 
              closeButton>
                <Offcanvas.Title 
                id={`offcanvasNavbarLabel-expand-${expand}`} >
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body 
                className="main-nav-bar-body-container" >

                <Nav className="justify-content-end flex-grow-1 pe-3 main-nav-bar-body-main" >
                <Nav.Link 
                    as={NavLink}
                    to="/"
                    href="/"
                    className="white-text main-nav-link nav-button-link" 
                    style={{ textDecoration: 'none' }} 
                    >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/solutions" 
                    href="/solutions"
                    className="white-text main-nav-link nav-button-link" 
                    style={{ textDecoration: 'none' }} >
                    Solutions
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/contact-us" 
                    href="/contact-us"
                    className="white-text main-nav-link nav-button-link" 
                    style={{ textDecoration: 'none' }} >
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </div>
  );
}

export default NavigationBar;