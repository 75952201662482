import React, { useReducer } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';

import axios from 'axios';
import formReducer from '../reducers/FormReducer';


//INITIAL STATE
let initialRequestQuoteFormState = {
    productName: "",
    contactPerson: "",
    emailAddress: "",
    contactNumber: "",
    message: "",
};

const RequestQuoteModal = (props) => {

    //STATES AND REDUCERS
    let [state, dispatch] = useReducer(formReducer, initialRequestQuoteFormState);
    //const [show, setShow] = useState(false);

    // GET VALUE OF PRODUCT TITLE FROM LOCAL STORAGE
    let productName = localStorage.getItem('productTitle');

    //HANDLE THE FORM FIELDS INPUT
    const handleTextChange = (event) => {
        dispatch({
            type: "HANDLE REQUEST QUOTE",
            field: event.target.name,
            payload: event.target.value,
        });
    };

    //HANDLE FOR SUBMISSION.
    const handleFormSubmit = (event) => {
        let data = state;
        //console.log(data);
        sendContactForm(data);
        event.preventDefault();
    }

    //HANDLE THE SENDING OF DATA TO THE NODE API.
    const sendContactForm = (data) => {
        axios.post(`${process.env.REACT_APP_MAILER_API_URL}/gas-fill-request-quote`, data)
        .then(response => {
            let result = response.data;
            if (result.status === "Success") {
                console.log('Email Sent!');
                props.notifyToastSuccess();
                formReset();
                props.onHide();
            }else {
                console.log("Error");
                props.notifyToastFailed();
            }
            //console.log(result.data);
        });
    }


    // RESET FORM FUNCTION
    const formReset = () =>{
        dispatch({type: "HANDLE FORM RESET"});
    }


  return (
    <div>
        <Modal 
            {...props} 
            centered 
            aria-labelledby="contained-modal-title-vcenter" 
            className='request-quote-modal-container modal-xl'
            show={props.show}
            onHide={props.onHide}
        >
        <div className='request-quote-modal-div'>
            <Modal.Header closeButton className='request-quote-modal-header'>
                <Modal.Title className='request-quote-title'>REQUEST A QUOTE</Modal.Title>
            </Modal.Header>
            <Modal.Body className='request-quote-modal-body'>
            <Form method = 'POST' onSubmit={handleFormSubmit}>
                <Container fluid>
                <Row>                    
                    <Col lg={12} className='request-quote-modal-Col'>
                        <Form.Group className="mb-3 request-quote-modal-item" controlId="phoneNumber">
                            <Form.Label className='request-quote-product' visuallyHidden='true'>Requested Product for Quotation</Form.Label>
                            <Form.Control className='request-quote-modal-input'
                            type="text"
                            placeholder={productName}
                            name='productName'
                            value={state.productName = productName}
                            onChange={handleTextChange}
                            disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className='request-quote-modal-Col'>
                        <Form.Group className="mb-3 request-quote-modal-item" controlId="fullName">
                            <Form.Label className='request-quote-name' visuallyHidden='true'>Full Name</Form.Label>
                                <FloatingLabel label='Name'>
                                <Form.Control className='request-quote-modal-input'
                                type="text"
                                placeholder="Full Name"
                                autoFocus
                                required={true}
                                name='contactPerson'
                                value={state.contactPerson} 
                                onChange={handleTextChange}
                                />
                                </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3 request-quote-modal-item" controlId="emailAddress">
                            <Form.Label className='request-quote-email' visuallyHidden='true'>Email</Form.Label>
                            <FloatingLabel label='Email'>
                            <Form.Control className='request-quote-modal-input'
                                type="email"
                                placeholder="Email"
                                required={true}
                                name='emailAddress'
                                value={state.emailAddress}
                                onChange={handleTextChange}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3 request-quote-modal-item" controlId="phoneNumber">
                            <Form.Label className='request-quote-phone' visuallyHidden='true'>Phone</Form.Label>
                            <FloatingLabel label="Phone">
                            <Form.Control className='request-quote-modal-input'
                                type="tel"
                                placeholder="Phone"
                                required={true}
                                name='contactNumber'
                                value={state.contactNumber}
                                onChange={handleTextChange}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col lg={6} className='request-quote-modal-Col'>
                        <Form.Group className="mb-3 request-quote-modal-item" controlId="Message" >
                        <Form.Label visuallyHidden='true' className='request-quote-message'>Message</Form.Label>
                            <FloatingLabel label='Message'>
                            <Form.Control as="textarea" className='request-quote-modal-input'
                                placeholder='Message'
                                name='message'
                                value={state.message}
                                style={{ height: '207px' }}
                                onChange={handleTextChange}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col lg={{ span: 4, offset: 4 }} className='request-quote-modal-Col'>
                        <Button 
                            type="submit"
                            className='send-request-btn'
                            /**handles the Google Analytics triggering when the form field (contactPerson, emailAddress, contactNumber) are all filled out*/
                            onClick={
                                state.contactPerson.length === 0 ? null 
                                : state.emailAddress.length === 0 ? null 
                                : state.contactNumber.length === 0 ? null 
                                : props.eventTrack.bind(this, "solutions_items_form_submissions", "forms_submissions", productName)
                            }
                        >
                            SEND REQUEST
                        </Button>
                    </Col>                
                </Row>
                </Container>
                </Form>
            </Modal.Body>
        </div>
        </Modal>
       
    </div>
  )
}
export default RequestQuoteModal;