import React from 'react';

const AboutUs = () => {
    return (
        <div className='about-us-container' data-aos="fade-up">
            <h3 className='about-us-header'>About Us</h3>
            <p className='about-us-text' data-aos="fade-right">
            Gas Fill Australia (GFA) started out from Kontrols &Industrial Weighing (CI KIW) with its inception in 
                June 1998. It was formed to take over the business of Philips Scientific & 
                Industrial Division (Industrial Weighing) in Australia. CI KIW specialized 
                in the design and manufacturing of gas filling systems under the aegis of its 
                shareholder Colin Tassell. With industry experience, CI KIW began building a name for 
                itself by designing and building these systems to bring huge cost savings to clients in 
                the gas industry. 
                <br/> <br/>
                <span data-aos="fade-up">
                    GFA, now a division of CISCAL Group of Companies, is the industry’s choice for manual, 
                    semi-automatic, and automatic batching/filling solutions. We are manufacturers of 
                    Australia’s best gas filling systems while having core strengths in both the 
                    pharmaceutical industry and in all hazardous area applications of weighing.
                </span>
                <br/> <br/>
                
            </p>
        </div>
    );
};

export default AboutUs;