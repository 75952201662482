import React from 'react';
import Button from '@mui/material/Button';

function Certificates() {
  return (
    <div className='certificates-page-container'>
        <div className='certificates-page-title'>
            <h1>CERTIFICATES</h1>
        </div>
        <div className='certificates-page-list'>
            <div>
            <ul>
                <li>
                    <Button
                        className='check-solutions-right'
                        variant="outlined"
                        color="primary"
                        size="large"
                        href='https://cisweb.sgp1.digitaloceanspaces.com/Uploads/2022/06/SL-0266-Licence-Certificate-5.7.21-to-5.7.2024-2.pdf'
                        target='_blank'
                        >
                        National Measuring Institute Certificate
                    </Button>
                </li>

                <li>
                    <Button
                        className='check-solutions-right'
                        variant="outlined"
                        color="primary"
                        size="large"
                        href='https://cisweb.sgp1.digitaloceanspaces.com/Certificates/00411%2000404%20Cert%202023.pdf'
                        target='_blank'
                        >
                        NATA Calibration Certificate
                    </Button>
                </li>
    
                <li>
                    <Button
                        className='check-solutions-right'
                        variant="outlined"
                        color="primary"
                        size="large"
                        href='https://cisweb.sgp1.digitaloceanspaces.com/Certificates%2FCI%20Scientific%2023-24%20Certificate%20of%20Currency.pdf'
                        target='_blank'
                        >
                        Primary Liability Insurance Certifiate of Currency
                    </Button>
                </li>
    
                <li>
                    <Button
                        className='check-solutions-right'
                        variant="outlined"
                        color="primary"
                        size="large"
                        href='https://cisweb.sgp1.digitaloceanspaces.com/Certificates%2FNSW-Workers%20Insurance%20Certificate%20of%20Currency.pdf'
                        target='_blank'
                        >
                        Workers Compensation Certificates(NSW)
                    </Button>
                </li>

                <li>
                    <Button
                        className='check-solutions-right'
                        variant="outlined"
                        color="primary"
                        size="large"
                        href='https://cisweb.sgp1.digitaloceanspaces.com/Certificates/2FVIC-Workers%20Insurance%20Certificate%20of%20Currency.pdf'
                        target='_blank'
                        >
                        Workers Compensation Certificates(VIC)
                    </Button>
                </li>
            </ul>

            </div>

        </div>

    </div>
  )
}

export default Certificates