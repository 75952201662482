import { React } from 'react';

import Banner from '../components/Banner' //'../components/HomePageBannerSlider';
// import Introduction from '../components/Introduction';
import IntroSection from '../components/IntroSection';
import CardsSection from '../components/CardsSection';
import BusinessStats from '../components/BusinessStats';
import AboutUs from '../components/AboutUs';
import GroupOfCompanies from '../components/groupOfCompanies';
import Accreditations from '../components/Accreditations';
import ContactForm from '../components/ContactForm';
import NewsLetter from '../components/NewsLetter';
import QRCode from '../components/QRCode';



const HomePage = (props) => {
    

    return (
        <div className='hompage-container' id='home'>
            <Banner eventTrack={props.eventTrack}/>
            {/* <Introduction eventTrack={props.eventTrack}/> */}
            <IntroSection />
            <CardsSection />
            <BusinessStats />
            <AboutUs />
            <GroupOfCompanies />
            <Accreditations />
            <ContactForm notifyToastSuccess={props.notifyToastSuccess} notifyToastFailed={props.notifyToastFailed} eventTrack={props.eventTrack} />
            {/* <QRCode /> */}
            <NewsLetter notifyNewsLetterSuccess={props.notifyNewsLetterSuccess} notifyDuplicateNewsLetterRecord={props.notifyDuplicateNewsLetterRecord} eventTrack={props.eventTrack}/>

        </div>
    );
};

export default HomePage;