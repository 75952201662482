import { useEffect } from "react";
import { useLocation } from "react-router-dom";

//handles the scroll to top behavior when navigating to other pages.
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    
      return null;
};

export default ScrollToTop;