import React, {  } from 'react';
//import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Button from 'react-bootstrap/Button';
//import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// IMAGES
import valueForMoneyIcon from '../website-images/value-for-money-icon.svg';
import systemProgramIcon from '../website-images/system-program-icon.svg';
import easeOfUse from '../website-images/ease-of-use-icon.svg';
import upgradesAndTradeInsIcon from '../website-images/upgrades-and-trade-ins-icon.svg';
import payAsYouUseIcon from '../website-images/pay-as-you-use-icon.svg';
import informationIcon from '../website-images/information-icon.svg';
import LPG from '../website-images/LPG.jpg';
import CO2 from '../website-images/CO2.png';
import otherGas from '../website-images/OTHER GAS.jpg';

// MODELS & SPECS TABLE COMPONENTS
//import LPGEX1Features from '../components/LPGEx-1Features';
//import LPGEx2Features from '../components/LPGEx-2Features';
//import LPGM4MAXXFeatures from '../components/LPGM4-MAXXFeatures';

export default function AcetyleneGasFillingSystems(props) {

    const images = [

        {
        original: '..//website-images/Gas Cylinder Storage.jpg',
        thumbnail: '../website-images/Gas Cylinder Storage.jpg',
        originalWidth: 1024,
        thumbnailWidth: 100
        },
    ]

    // REQUEST A QUOTE MODAL HANDLER
    const HandleOpenModal = () => {
        props.openedModal(true);

        // Save product title to local storage
        let value = document.getElementById('Acetylene-title-id').innerText;
        localStorage.setItem('productTitle', value)
        console.log(value);
    };

  return (
    <div className='LPG-Filling-container'>
    {/* INFO SECTION */}
    <div className='LPG-info-div container'>
        <div className='row'>
        <div className='carousel-div col col-12 col-lg-6'>
            <ImageGallery items={images} />
        </div>
        <div className='col col-12 col-lg-6'>
            <h2 className='LPG-info-title' id='Acetylene-title-id'>ACETYLENE GAS FILLING</h2>
            <p>
                Introducing Gas Fill Australia’s solution to residual gas calculation and acetone filling. All new re-acetoning machine is able to handle the addition of 
                acetone into acetylene cylinders in a reliable and fast manner.
            </p>
            <p>
                The GFA’s design consists of a weighing platform, local operator interface (incorporating a message screen and keypad) and an acetone connection in combination 
                with temperature and pressure measurement capabilities. A pneumatically operated control valve doses acetone into the acetylene cylinders. 
                All production information is collected and analysed by Gas Fill AUstralia’s exclusive FILL RIGHT KONTROL software which operates in a Microsoft Windows environment.
            </p>
            

            <div className='LPG-button-div'>
            <Button className='LPG-request-quote' size='lg' onClick={HandleOpenModal}>REQUEST A QUOTE</Button>
            </div>
        </div>
        </div>
    </div>

    {/* ADVANTAGES SECTION */}
    <div className='LPG-Advantages-div'>
        <h2 className='LPG-advantages-title'>ADVANTAGES</h2>

        <div className='LPG-advantages-content container'>
        <div className='row  align-items-center'>

            <div className='col col-12 col-md-3 p-3 advantages-img-div'>
                <img src={valueForMoneyIcon} className='advantages-img' alt='value for money'/>
                <h4>VALUE FOR MONEY</h4>
            </div>
            <div className='col col-12 col-md-9 p-3'>
                <p>
                    All Components are Class 1 Zone 1 IIC T3 or better, suitable for acetylene environment. Approved for use in hazardous environments world wide. 
                    Systems can be made for European (Cenelac), United States (FM approved) and Australian (Aust. Approved) markets.
                </p>
            </div>

            <div className='col col-12 col-md-3 p-3 order-md-2'>
                <img src={easeOfUse} className='advantages-img' alt='ease of use'/>
                <h4>SPEED AND ACCURACY</h4>
            </div>
            <div className='col col-12 col-md-9 p-3 order-md-1'>
                <p>
                    Fast turnover of cylinders being weighed in or weighed out. Weigh in 20-25 seconds / weigh out 6-10 seconds. 
                    Highly accurate and reliable batching of acetone into acetylene cylinders. Currently batching within ±20g. 
                    Weighing In rate of 150 cylinders/hr and weigh out rate of 600 cylinders/hr achieved.
                </p>
            </div>
            <div className='col col-12 col-md-3 p-3 order-md-3'>
                <img src={systemProgramIcon} className='advantages-img' alt='system program'/>
                <h4>PRODUCT REPORTING</h4>
            </div>
            <div className='col col-12 col-md-9 p-3 order-md-4'>
                <p>
                    Shift summary reports, individual rack reports, cylinder weigh on or weigh off reports are available. 
                    They automatically count the number of cylinders that passed, need to be blown down, topped up or are defective, among other features.
                </p>
                
                <p>Not only this there is also well over 20 years of building these systems as well as our friendly team who are there to support our customers to ensure ease of use.</p>
            </div>

        </div>
        </div>

    </div>

    {/* MODELS & SPECIFICATIONS SECTION */}
    {/*
    <div className='LPG-models-specs-div'>
        <h2 className='LPG-models-specs-title'>MODELS AND SPECIFICATIONS</h2>
        <Accordion defaultActiveKey={['0']} alwaysOpen className='accordion-div'>
        <Accordion.Item eventKey="0" className='accordion-item'>
            <Accordion.Header className='accordion-header'>EX-1</Accordion.Header>
            <Accordion.Body className='accordion-body'>
            <LPGEX1Features />
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className='accordion-item'>
            <Accordion.Header className='accordion-header'>EX-2</Accordion.Header>
            <Accordion.Body className='accordion-body'>
            <LPGEx2Features />
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className='accordion-item'>
            <Accordion.Header className='accordion-header'>M4 MAXX</Accordion.Header>
            <Accordion.Body className='accordion-body'>
            <LPGM4MAXXFeatures />
            </Accordion.Body>
        </Accordion.Item>
        </Accordion>
    </div>
     */}

    {/* OTHER INFO SECTION */}
    <div className='other-info-div container'>
        <div className='row'>
            <div className='col col-12 col-md-6'>
                <img src={upgradesAndTradeInsIcon}  className='upgrades-and-tradeIns-icon' alt='upgrades and trade in'/>
                <h3>UPGRADES & TRADE-INS</h3>

                <div>
                    <p>
                        Do you have an older machine but not the money to upgrade? Gas Fill Australia have you covered.  We are able to upgrade your 
                        old system to our newest systems for a fraction of  the cost of a new system. We have multiple upgrade options available that can either be done at 
                        one of our locations or have an upgrade kit sent to you for your local electrician to do.
                    </p>

                    <div className='info-cards'>
                        <img src={informationIcon} className='info-icon' alt='information icon'/>
                        <p>
                            If an upgrade is not something you are after, then you can also look at a trade in on your old system for our new system. Minimum amount 
                            received towards your new purchase is <span>$500</span> on the system.
                        </p>
                    </div>
                </div>
            </div>
            <div className='col col-12 col-md-6'>
                <img src={payAsYouUseIcon} className='pay-as-you-use-icon'alt='pay as you use'/>
                <h3>PAY AS YOU USE</h3>

                <div>
                <p>
                    If you are in the market for a reliable system but funds are tight Gas Fill Australia have a system for you. With our pay as you use system you 
                    are charged per cylinder filled. This means during those quiet times our systems costs nothing. Under this system you automatically gain access to our premium 
                    range of machines. This also includes your annual calibration costs and repairs for our machines. It also means every 5 years you get the benefit of a NEW Machine with no extra charges.  To get more information speak to one of our 
                    friendly team.
                </p>

                    <div className='info-cards'>
                        <img src={informationIcon} className='info-icon' alt='information icon'/>
                        <p>You automatically get access to our premium machine</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* RELATED PRODUCTS SECTION */}
    <div className='related-products-div'>
        <h2>RELATED PRODUCTS</h2>
        <Container className='related-products-cards-container'>
        <Row className='related-products-cards row'>
        <Col sm={4}>
            <Card className="related-products-card text-white">
            <Card.Img src={CO2} alt="Card image" className='related-products-img' />
            <Card.ImgOverlay className='related-products-overlay'>
                <Card.Title className='related-products-overlay-title'>CO2 FILLING SYSTEM</Card.Title>
                <Card.Text className='related-products-overlay-btn-div'>
                <Link to="/solutions/co2-filling-systems"><Button className='related-products-overlay-btn'>VIEW PRODUCT</Button></Link>
                </Card.Text>
            </Card.ImgOverlay>
            </Card>
        </Col>
        <Col sm={4}>
            <Card className="related-products-card text-white">
            <Card.Img src={otherGas} alt="Card image" className='related-products-img'/>
            <Card.ImgOverlay className='related-products-overlay'>
                <Card.Title className='related-products-overlay-title'>OTHER GAS FILLING</Card.Title>
                <Card.Text className='related-products-overlay-btn-div'>
                <Link to="/solutions/other-filling-systems"><Button className='related-products-overlay-btn'>VIEW PRODUCT</Button></Link>
                </Card.Text>
            </Card.ImgOverlay>
            </Card>
        </Col>
        <Col sm={4}>
            <Card className=" related-products-card text-white">
            <Card.Img src={LPG} alt="Card image" className='related-products-img'/>
            <Card.ImgOverlay className='related-products-overlay'>
                <Card.Title className='related-products-overlay-title'>LPG FILLING SYSTEMS</Card.Title>
                <Card.Text className='related-products-overlay-btn-div'>
                <Link to="/solutions/lpg-filling-systems"><Button className='related-products-overlay-btn'>VIEW PRODUCT</Button></Link>
                </Card.Text>
            </Card.ImgOverlay>
            </Card>
        </Col>
        </Row>
        </Container>

    </div>
</div>
  )
}
