import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className='terms-and-conditions-container'>
            <div className='main-document'>
                <h1 className='document-title'>Terms & Conditions</h1>
                <h4 className='document-subtitle'>Website Use Terms & Conditions</h4>
                <p className='document-text' id='document-intro'>
                    This website <b>www.ciscientific.com</b> is operated by CI Scientific Pty Ltd 
                    (we, our or us). It is available at: www.ciscientific.com and may be available 
                    through other addresses or channels.
                </p>

                <ol type = "A">
                    <li className='list-title'>Consent</li>
                    <p className='document-text'>
                        We may, at any time and at our discretion, vary these Terms by publishing 
                        the varied terms on our Site. We recommend you check our Site regularly to 
                        ensure you are aware of our current terms. Materials and information on this 
                        Site are subject to change without notice. We do not undertake to keep our 
                        Site up-to-date and we are not liable if any Content is inaccurate or 
                        out-of-date.
                    </p>

                    <li className='list-title'>Variations</li>
                    <p className='document-text'>
                        Sensitive information is defined in the Privacy Policy to include 
                        information or opinion about such things as an individual’s racial or ethnic 
                        origin, political opinions, membership of a political association, religious 
                        or philosophical beliefs, membership of a trade union or other professional 
                        body, criminal record or health information.
                    </p>

                    <li className='list-title'>License to use our Site</li>
                    <p className='document-text'>
                        We grant you a non-exclusive, royalty-free, revocable, worldwide, 
                        non-transferable license to use our Site in accordance with these Terms. 
                        All other uses are prohibited without our prior written consent.
                    </p>

                    <li className='list-title'>Prohibited Conduct</li>
                    <p className='document-text'>
                    You must not do or attempt to do anything: that is unlawful; 
                    prohibited by any laws applicable to our Site; which we would consider 
                    inappropriate; or which might bring us or our Site into disrepute, including 
                    (without limitation):
                    <br/>

                    <ol type = "a">
                        <li className='sub-list-title'>Anything that would constitute a breach of an individual’s privacy (including uploading private or personal information without an individual’s consent) or any other legal rights;</li>
                        <li className='sub-list-title'>Using our Site to defame, harass, threaten, menace or offend any person;</li>
                        <li className='sub-list-title'>Interfering with any user using our Site;</li>
                        <li className='sub-list-title'>Tampering with or modifying our Site, knowingly transmitting viruses or other disabling features, or damaging or interfering with our Site, including (without limitation) using trojan horses, viruses or piracy or programming routines that may damage or interfere with our Site;</li>
                        <li className='sub-list-title'>Using our Site to send unsolicited email messages; or</li>
                        <li className='sub-list-title'>Facilitating or assisting a third party to do any of the above acts.</li>
                    </ol>
                    </p>

                    <li className='list-title'>Exclusion of Competitors</li>
                    <p className='document-text'>
                        You are prohibited from using our Site, including the Content, in any way 
                        that competes with our business.
                        <br/><br/>
                        When your Personal Information is no longer needed for the purpose for 
                        which it was obtained, we will take reasonable steps to destroy or 
                        permanently de-identify your Personal Information. However, most of the 
                        Personal Information is or will be stored in client files which will be kept 
                        by us for a minimum of 7 years.
                    </p>

                    <li className='list-title'>Non-Commercial Use</li>
                    <p className='document-text'>
                        Our Site is for your personal, non-commercial use only. 
                        You must not use our Site, or any of the Content, for commercial purposes, 
                        including any advertising or advertising revenue generation activity on your 
                        own website or any other platform, without obtaining a license to do so 
                        from us.
                    </p>

                    <li className='list-title'>Information</li>
                    <p className='document-text'>
                        The Content is not comprehensive and is for general information purposes only. 
                        It does not consider your specific needs, objectives or circumstances, and it
                         is not advice. While we use reasonable attempts to ensure the accuracy and 
                         completeness of the Content, we make no representation or warranty in 
                         relation to it, to the maximum extent permitted by law.
                    </p>

                    <li className='list-title'>Intellectual Property Rights</li>
                    <p className='document-text'>
                        Unless otherwise indicated, we own or license all rights, 
                        title and interest (including intellectual property rights) in our Site and 
                        all the Content. Your use of our Site and your use of and access to any 
                        Content does not grant or transfer to you any rights, title or interest in 
                        relation to our Site or the Content. You must not:

                        <ol type = "a">
                            <li className='sub-list-title'>copy or use, in whole or in part, any Content;</li>
                            <li className='sub-list-title'> reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any Content to any third party; or</li>
                            <li className='sub-list-title'> breach any intellectual property rights connected with our Site or the Content, including (without limitation) altering or modifying any of the Content, causing any of the Content to be framed or embedded in another website or platform, or creating derivative works from the Content.</li>
                        </ol>
                    </p>

                    <li className='list-title'>User Content</li>
                    <p className='document-text'>
                        You may be permitted to post, upload, publish, submit or transmit relevant 
                        information and content (User Content) on our Site. By making available any 
                        User Content on or through our Site, you grant to us a worldwide, 
                        irrevocable, perpetual, non-exclusive, transferable, royalty-free license 
                        to use the User Content, with the right to use, view, copy, adapt, modify, 
                        distribute, license, sell, transfer, communicate, publicly display, publicly 
                        perform, transmit, stream, broadcast, access, or otherwise exploit such User 
                        Content on, through or by means of our Site.
                        <br/><br/>
                        You agree that you are solely responsible for all User Content that you 
                        make available on or through our Site. You represent and warrant that:
                        <br/>
                        <ol type = "a">
                            <li className='sub-list-title'>you are either the sole and exclusive owner of all User Content or you have all rights, licenses, consents and releases that are necessary to grant to us the rights in such User Content (as contemplated by these Terms); and</li>
                            <li className='sub-list-title'>
                                neither the User Content nor the posting, uploading, publication, 
                                submission or transmission of the User Content or our use of the 
                                User Content on, through or by means of our Site will infringe, 
                                misappropriate or violate a third party’s intellectual property 
                                rights, or rights of publicity or privacy, or result in the 
                                violation of any applicable law or regulation.
                                <br/><br/>
                                We do not endorse or approve, and are not responsible for, any User 
                                Content. We may, at any time (at our sole discretion), remove any 
                                User Content.
                            </li>
                        </ol>
                    </p>

                    <li className='list-title'>Third Party Sites</li>
                    <p className='document-text'>
                        Our Site may contain links to websites operated by third parties. 
                        Unless expressly stated otherwise, we do not control, endorse or approve, 
                        and are not responsible for, the content on those websites. You should make 
                        your own investigations with respect to the suitability of those websites.
                    </p>

                    <li className='list-title'>Discontinuance</li>
                    <p className='document-text'>
                        We may, at any time and without notice to you, discontinue our Site, 
                        in whole or in part. We may also exclude any person from using our Site, 
                        at any time and at our sole discretion. We are not responsible for any 
                        Liability you may suffer arising from or in connection with any such 
                        discontinuance or exclusion.
                    </p>

                    <li className='list-title'>Warranties and Disclaimers</li>
                    <p className='document-text'>
                        To the maximum extent permitted by law, we make no representations or 
                        warranties about our Site or the Content, including (without limitation) 
                        that:
                        <br/>
                        <ol type = "a" >
                            <li className='sub-list-title'>They are complete, accurate, reliable, up-to-date and suitable for any purpose;</li>
                            <li className='sub-list-title'>Access will be uninterrupted, error-free or free from viruses; or</li>
                            <li className='sub-list-title'>Our Site will be secure.</li>
                            <li className='sub-list-title'>You read, use and act on our Site and the Content at your own risk.</li>
                        </ol>
                    </p>

                    <li className='list-title'> Limitation of liability</li>
                    <p className='document-text'>
                        To the maximum extent permitted by law, we are not responsible for any loss, 
                        damage or expense, howsoever arising, whether direct or indirect and/or 
                        whether present, unascertained, future or contingent (Liability) suffered by 
                        you or any third party, arising from or in connection with your use of our 
                        Site and/or the Content and/or any inaccessibility of, interruption to or 
                        outage of our Site and/or any loss or corruption of data and/or the fact 
                        that the Content is incorrect, incomplete or out-of-date.
                    </p>

                    <li className='list-title'>Indemnity</li>
                    <p className='document-text'>
                        To the maximum extent permitted by law, you must indemnify us, and hold us 
                        harmless, against any Liability suffered or incurred by us arising from or 
                        in connection with your use of our Site or any breach of these Terms or any 
                        applicable laws by you. This indemnity is a continuing obligation, 
                        independent from the other obligations under these Terms, and continues 
                        after these Terms end. It is not necessary for us to suffer or incur any 
                        Liability before enforcing a right of indemnity under these Terms.
                    </p>

                    <li className='list-title'>Termination</li>
                    <p className='document-text'>
                        These Terms are effective until terminated by us, which we may do at any 
                        time and without notice to you. In the event of termination, all 
                        restrictions imposed on you by these Terms and limitations of liability set 
                        out in these Terms will survive.
                    </p>

                    <li className='list-title'>Disputes</li>
                    <p className='document-text'>
                        In the event of any dispute arising from, or in connection with, these 
                        Terms (Dispute), the party claiming there is a Dispute must give written 
                        notice to the other party setting out the details of the Dispute and 
                        proposing a resolution. Within 7 days after receiving the notice, the 
                        parties must, by their senior executives or senior managers 
                        (who have the authority to reach a resolution on behalf of the party), 
                        meet at least once to attempt to resolve the Dispute or agree on the 
                        method of resolving the Dispute by other means, in good faith. 
                        All aspects of every such conference, except the fact of the occurrence 
                        of the conference, will be privileged. If the parties do not resolve the 
                        Dispute, or (if the Dispute is not resolved) agree on an alternate method 
                        to resolve the Dispute, within 21 days after receipt of the notice, the 
                        Dispute may be referred by either party (by notice in writing to the other 
                        party) to litigation.
                    </p>

                    <li className='list-title'>Severance</li>
                    <p className='document-text'>
                        If a provision of these Terms is held to be void, invalid, illegal 
                        or unenforceable, that provision must be read down as narrowly as necessary 
                        to allow it to be valid or enforceable. If it is not possible to read down a 
                        provision (in whole or in part), that provision (or that part of that 
                        provision) is severed from these Terms without affecting the validity or 
                        enforceability of the remainder of that provision or the other provisions 
                        in these Terms.
                    </p>

                    <li className='list-title'>Jurisdiction</li>
                    <p className='document-text'>
                        Your use of our Site and these Terms are governed by the laws of 
                        New South Wales. You irrevocably and unconditionally submit to the exclusive 
                        jurisdiction of the courts operating in New South Wales and any courts 
                        entitled to hear appeals from those courts and waive any right to object 
                        to proceedings being brought in those courts.
                        <br/><br/>
                        Our Site may be accessed throughout Australia and overseas. We make no 
                        representation that our Site complies with the laws (including intellectual 
                        property laws) of any country outside Australia. If you access our Site 
                        from outside Australia, you do so at your own risk and are responsible for 
                        complying with the laws of the jurisdiction where you access our Site.
                    </p>
                </ol>

            </div>
        </div>
    );
};

export default TermsAndConditions;