import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import headerImage from '../website-images/LPG.jpg';

const LPGFillingSystemsSolutions = (props) => {
    return (
        <div className='solutions-item-container-left' data-aos="fade-up">
            <div className='solutions-item-header-left'>
                <div className='solutions-item-image-container-left'>
                    <img src={headerImage} alt='lpg filling systems' className='solutions-item-image-left'/>
                </div>
            </div>
            <div className='solutions-item-body-left'>
                <div className='solutions-item-texts-left'>
                    <h2>LPG Filling Systems</h2>
                    <p>
                        Our LPG systems provide significant advantages for our customers. 
                        Working on the proven Platforms of the earlier designs of the system, 
                        we have upgraded the operating system to our new M4 MAX SERIES as well as 
                        the all-new EX SERIES. These systems provide you with the cost savings and added 
                        benefits of the latest technology, leaving the competition behind.
                    </p>
                </div>
                <div className='solutions-item-actions-left'>
                <Link to='/solutions/lpg-filling-systems' className='solution-button-link'>
                    <Button
                        className='check-solutions-left'
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={props.eventTrack.bind(this, "solutions_page_buttons", "button_clicks", "lpg_checkthisout_button")}
                        >
                        Check This Out
                    </Button>
                </Link>
                </div>
            </div>
        </div>
    );
};

export default LPGFillingSystemsSolutions;