import React, { Component } from 'react';
import Stack from 'react-bootstrap/Stack';

import CIScientific from '../website-images/MicrosoftTeams-image.png';
import CISCALTestAndTag from '../website-images/MicrosoftTeams-image (4).png';
import CISCALWeighing from '../website-images/MicrosoftTeams-image (1).png';
import ActiveInstruments from '../website-images/MicrosoftTeams-image (2).png';
import AutoclaveAustralia from '../website-images/MicrosoftTeams-image (3).png';
import GasFillAustralia from '../website-images/MicrosoftTeams-image (5).png';
import MySparkyMate from '../website-images/MicrosoftTeams-image (6).png';


class groupOfCompanies extends Component {
    render() {
        return (
            <div className='ci-companies-section' data-aos="fade-up">
                <h2 className='title-header'>Our Divisions</h2>
                <Stack className='desktop-stack' direction='horizontal' gap={1}>
                    <div className='divisions'><img src={CIScientific} alt='ci scientific' className='company-logos' /></div>
                    <div className='divisions'><img src={CISCALTestAndTag} alt='ciscal test and tag' className='company-logos' /></div>
                    <div className='divisions'><img src={CISCALWeighing} alt='ciscal weighing' className='company-logos' /></div>
                    <div className='divisions'><img src={ActiveInstruments} alt='active instruments' className='company-logos' /></div>
                    <div className='divisions'><img src={AutoclaveAustralia} alt='autoclave australia' className='company-logos' /></div>
                    <div className='divisions'><img src={GasFillAustralia} alt='gas-fill australia' className='company-logos' /></div>
                    <div className='divisions'><img src={MySparkyMate} alt='my sparkymate' className='company-logos' /></div>
                </Stack>

                <Stack direction='vertical' className='main-stack'>
                    <Stack direction='horizontal' id='middle'>
                        <div className='divisions'><img src={CIScientific} alt='ci scientific' className='company-logos' /></div>
                        <div className='divisions'><img src={CISCALTestAndTag} alt='ciscal test and tag' className='company-logos' /></div>
                        <div className='divisions'><img src={CISCALWeighing} alt='ciscal weighing' className='company-logos' /></div>
                    </Stack>
                    <Stack direction='horizontal' id='bottom'>
                        <div className='divisions'><img src={ActiveInstruments} alt='active instruments' className='company-logos' /></div>
                        <div className='divisions'><img src={AutoclaveAustralia} alt='autoclave australia' className='company-logos' /></div>
                        <div className='divisions'><img src={GasFillAustralia} alt='gas-fill australia' className='company-logos' /></div>
                        <div className='divisions'><img src={MySparkyMate} alt='my sparkymate' className='company-logos' /></div>
                    </Stack>
                    
                </Stack>
            </div>
        );
    }
}

export default groupOfCompanies;