import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import headerImage from '../website-images/CO2.png';

const CO2GasFillingSystemsSolutions = (props) => {
    return (
        <div className='solutions-item-container-right' data-aos="fade-up">
            <div className='solutions-item-header-right'>
                <div className='solutions-item-image-container-right'>
                    <img src={headerImage} alt='lpg filling systems' className='solutions-item-image-right'/>
                </div>
            </div>
            <div className='solutions-item-body-right'>
                <div className='solutions-item-texts-right'>
                    <h2>CO2 Gas Filling Machine</h2>
                    <p>
                        Our CO2 systems provide significant advantages for our customers. 
                        Working on the proven modular design of earlier platforms, we have 
                        upgraded the operating system to our new CO2 4 Series and CO2 5 Series. 
                        These systems provide you with the cost savings and added benefits of the 
                        latest technology, leaving the competition behind.
                    </p>
                </div>
                <div className='solutions-item-actions-right'>
                <Link to='/solutions/co2-filling-systems' className='solution-button-link'>
                    <Button
                        className='check-solutions-right'
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={props.eventTrack.bind(this, "solutions_page_buttons", "button_clicks", "co2_checkthisout_button")}
                        >
                        Check This Out
                    </Button>
                </Link>
                </div>
            </div>
        </div>
    );
};

export default CO2GasFillingSystemsSolutions;