import React from 'react';
import CISCAL from '../website-images/CISCAL.svg';
import FacebookIcon from '../website-images/facebook-icon.svg';
import InstagramIcon from '../website-images/instagram-icon.svg';
import YoutubeIcon from '../website-images/youtube-icon.svg';
import LinkedInIcon from '../website-images/linked-in-icon.svg';
import EmailIconFooter from '../website-images/email-icon-footer.svg';
import TelephoneIconFooter from '../website-images/telephone-icon-footer.svg';

export default function Footer(props) {
  return (
    <div className='footer-div' id='footer'>

      <div className='footer-content' data-aos="fade-up">
        <div className='footer-left'>
          <div className='footer-div-logo'>
            <img src={CISCAL} alt="ciscal-logo"/>
          </div>
          <div className='footer-socials'>
            <p>Our Social Media Channels:</p>
            <div className='footer-social-icons'>
              <a href='https://www.facebook.com/ciscientific/' target='_blank' rel='noreferrer'><img src={FacebookIcon} alt="facebook-icon" onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_facebook_link")} /></a>
              <a href='https://www.instagram.com/ci_scientific/' target='_blank' rel='noreferrer'><img src={InstagramIcon} alt="instagram-icon" onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_instagram_link")} /></a>
              <a href='https://www.youtube.com/user/CIScientific' target='_blank' rel='noreferrer'><img src={YoutubeIcon} alt="youtube-icon" onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_youtube_link")} /></a>
              <a href='https://www.linkedin.com/company/ci-scientific-pty-ltd/' target='_blank' rel='noreferrer'><img src={LinkedInIcon} alt="linkedin-icon" onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_linkedin_link")} /></a>
            </div>
          </div>
        </div>
  
        <div className='footer-right container'>        
          <div className='row'>
            <div className='footer-right-1 col col-12 col-md-6'>
              <p className='footer-title'>NSW OFFICE (HEAD OFFICE)</p>
              <p>Unit 1/9 Bearing Road, <br/>Seven Hills NSW 2147 <br/>Australia</p>
            </div>
    
            <div className='footer-right-2 col-12 col-md-6'>
              <p className='footer-title'>VIC OFFICE</p>
              <p>Unit 13/63 Ricky Way, <br/>Epping VIC 3076 <br/>Australia</p>
            </div>
    
            <div className='footer-right-3 col-12 col-md-6'>
              <p className='footer-title'>BUSINESS HOURS</p>
              <p>8:00 AM - 4:30 PM <br/>Monday - Friday</p>
            </div>
    
            <div className='footer-right-4 col-12 col-md-6'>
              <div className='footer-contacts'>
                <div className='footer-email' onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_email_button")}><a href='mailto:info@ciscientific.com'><img src={EmailIconFooter} alt="email-icon" /> info@ciscientific.com</a></div>
                <div className='footer-phone-number'onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_call_button")}><a href='tel:1300225542'><img src={TelephoneIconFooter} alt="telephone-icon" /> 1300-225-542</a></div>
              </div>
            </div>
  
            <div className='footer-right-5 col-12'>

              <a href="/certificates" target="_blank" rel='noreferrer'><span>CERTIFICATES</span></a>
              <a href="https://www.ciscaltech.com/customer-feedback" target="_blank" rel='noreferrer'><span>CUSTOMER FEEDBACK</span></a>
              <a href="/privacy-policy" target="_blank" rel='noreferrer'><span>PRIVACY POLICY</span></a>
              <a href="/loyalty-program-terms-condition" target="_blank" rel='noreferrer'><span>LOYALTY PROGRAM</span></a>
              <a href="/shipping-returns" target="_blank" rel='noreferrer'><span>SHIPPING & RETURNS</span></a>
              <a href="/terms-and-conditions" target="_blank" rel='noreferrer'><span>TERMS & CONDITIONS</span></a>

            </div>
          </div>
  
        </div>
      </div>

      <div className='footer-right-6'>
        <p>© Copyright CI Scientific Pty Ltd. 2019-2022. All Rights Reserved.</p>
      </div>

    </div>

  )
}
