import { React, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

//MAN PAGE COMPONENTS
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';

//IMPORTS THE RequestQuoteModal component
import RequestQuoteModal from './components/RequestQuoteModal';

//IMPORT HOLIDAY BREAK MODAL
//import HolidayBreakModal from './components/HolidayBreakModal';

//TOASTIFY
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import infoIcon from './website-images/information-icon.svg';
import errorInfoIcon from './website-images/errorInfoIcon.png';


//HANDLES THE SCROLL TO TOP ON PAGE CHANGE
import ScrollToTop from './components/ScrollToTop'; //handles the scroll to top behavior.
import PageReloader from './components/PageReloader';

//PAGES
import HomePage from './Pages/HomePage';
import ContactUsPage from './Pages/ContactUsPage';
import LPGFillingSystems from './Pages/LPGFillingSystems';
import AcetyleneGasFillingSystems from './Pages/AcetyleneGasFillingSystems';
import OtherGasFillingSystem from './Pages/OtherGasFillingSystem';
import CO2GasFillingSystems from './Pages/CO2GasFillingSystems';
import SolutionsPage from './Pages/SolutionsPage';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';
import ShippingAndReturns from './Pages/ShippingAndReturns';
import LoyaltyProgram from './Pages/LoyaltyProgram';
import PageNotFound from './Pages/PageNotFound';
import Certificates from './Pages/Certificates';

// REACT GOOGLE ANALYTICS
import ReactGoogleAnalytics  from 'react-ga4';

// INITIALIZATION OF REACT GOOGLE ANALYTICS
ReactGoogleAnalytics.initialize([{trackingId: "G-D1SGTET3XV"}]);
ReactGoogleAnalytics.send({ hitType: "pageview", page: window.location.pathname });


function App() {
  const [show, setShow] = useState(false);

  const handleClose = (event) =>{ 
    setShow(false);
    localStorage.clear();
  };

  useEffect(() => {
    AOS.init({ duration: 1200, once: false});
    AOS.refresh();
});



  //GOOGLE ANALYTICS CUSTOM EVENTS

  //custom events for buttons
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGoogleAnalytics.event({
      category: category,
      action: action,
      label: label,
    })
  };

  //CODE BLOCK FOR CHANGING THE PAGE TITLE == NEEDS TO BE RESOLVED
  function pageTitleHandler () {
    //gets the path name of the page.
    let pathname = window.location.pathname; 

    //regex to get the slash and dash of the path names
    let pathRegex = /[/]|-/i;

    //replaces the dash and slash of the pathname.
    let pathnameOne = pathname.replace(pathRegex, '');
    let finalRawPath = pathnameOne.replace (pathRegex, ' ');

    //splits and converts the dinalRawPath to sentence case/
    let sentenceCasePath = finalRawPath.split(' ').map(items => items[0].toUpperCase() + items.substring(1).toLowerCase());
    
    //joins the sentenceCasePath
    let finalPath = sentenceCasePath.join(' ');

    document.getElementById("page-title").innerHTML = `Gas Fill Australia | ${finalPath}`;
  }

  //implements the pageTitleHandler() function if the path is not the home path
  if (window.location.pathname !== "/") {
    pageTitleHandler();
  }

  //Toast Message
  const MsgSuccess = () => (
    <div>
      <h6 style={{fontSize: '16px'}}><strong>Message Sent!</strong></h6>
      <p style={{fontSize: '16px'}}>Our representatives will reach out to you shortly via email or phone.</p>
      <hr />
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
      <span><img src={infoIcon} alt='info-icon' style={{width: '30px', height: '30px'}}/></span>
      <p style={{marginLeft: '10px', display: 'inline', fontSize: '16px'}}>Please check your Junk/Spam folder if the email confirmation is not on your Inbox.</p>
      </div>
    </div>
  )
  
  const MsgFailed = () => (
    <div>
      <h6 style={{fontSize: '16px'}}><strong>Message Not Sent!</strong></h6>
      <p style={{fontSize: '16px'}}>We encountered some error on our end. Please Try again later.</p>
      <hr />
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
      <span><img src={errorInfoIcon} alt='info-icon' style={{width: '30px', height: '30px'}}/></span>
      <p style={{marginLeft: '10px', display: 'inline', fontSize: '16px'}}>Please check your internet connection also and see if the error still occurs.</p>
      </div>
    </div>
  )

  const NewsLetterSuccess = () => (
    <div>
      <h6 style={{fontSize: '16px'}}><strong>Thank you for your subscription!</strong></h6>
      <p style={{fontSize: '16px'}}>We appreciate you subscribing on our mailing list.</p>
      <hr />
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
      <span><img src={infoIcon} alt='info-icon' style={{width: '30px', height: '30px'}}/></span>
      <p style={{marginLeft: '10px', display: 'inline', fontSize: '16px'}}>Exciting thing awaits. Be ready for deals and discounts only available for our products.</p>
      </div>
    </div>
  )

  const DuplicateNewsLetterRecord = () => (
    <div>
      <h6 style={{fontSize: '16px'}}><strong>Already Subscribed.</strong></h6>
      <p style={{fontSize: '16px'}}>Thank you for your continued interest on subscribing on our mailing list!</p>
      <hr />
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
      <span><img src={infoIcon} alt='info-icon' style={{width: '30px', height: '30px'}}/></span>
      <p style={{marginLeft: '10px', display: 'inline', fontSize: '16px'}}>You are already subscribed using the email address that you provided.</p>
      </div>
    </div>
  )


    const notifyNewsLetterSuccess = () => toast(<NewsLetterSuccess />, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: false,
      });
  
      const notifyFailed = () => toast.error(<MsgFailed />, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: false,
        });

        const notifySuccess = () => toast(<MsgSuccess />, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: false,
          });



        const notifyDuplicateNewsLetterRecord = () => toast.info(<DuplicateNewsLetterRecord />, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: false,
          });


  return (
    <div className="App" id='main-app-container'>
      <BrowserRouter>
            <PageReloader />
            <ScrollToTop />
            <NavigationBar />
            <Routes>
              <Route path="/" exact element={<HomePage notifyToastSuccess={notifySuccess} notifyToastFailed={notifyFailed} notifyNewsLetterSuccess={notifyNewsLetterSuccess} notifyDuplicateNewsLetterRecord={notifyDuplicateNewsLetterRecord} eventTrack={eventTrack}/>} />
              <Route path="/solutions" element={<SolutionsPage eventTrack={eventTrack} />} />
              <Route path="/contact-us" element={<ContactUsPage notifyToastSuccess={notifySuccess} notifyToastFailed={notifyFailed} eventTrack={eventTrack} />} />
              <Route path="/solutions/lpg-filling-systems" element={<LPGFillingSystems openedModal={setShow} closeModal={handleClose} eventTrack={eventTrack} />} />
              <Route path="/solutions/co2-filling-systems" element={<CO2GasFillingSystems openedModal={setShow} closeModal={handleClose} eventTrack={eventTrack} />}/>
              <Route path="/solutions/acetylene-filling-systems" element={<AcetyleneGasFillingSystems openedModal={setShow} closeModal={handleClose} eventTrack={eventTrack} />}/>
              <Route path="/solutions/other-filling-systems" element={<OtherGasFillingSystem openedModal={setShow} closeModal={handleClose} eventTrack={eventTrack} />}/>
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/shipping-returns" element={<ShippingAndReturns />}/>
              <Route path="/loyalty-program-terms-condition" element={<LoyaltyProgram />}/>
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path='/certificates' element={<Certificates/>} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            <Footer eventTrack={eventTrack}/>
            <RequestQuoteModal show={show} onHide={handleClose} notifyToastSuccess={notifySuccess} notifyToastFailed={notifyFailed} eventTrack={eventTrack} />
            <ToastContainer className="toast-container" style={{width: '500px'}} />
            {/* <HolidayBreakModal /> */}
        </BrowserRouter>
    </div>
  );
};

export default App;
