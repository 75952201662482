import React, {  } from 'react';
//import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Button from 'react-bootstrap/Button';
//import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// IMAGES
import valueForMoneyIcon from '../website-images/value-for-money-icon.svg';
import systemProgramIcon from '../website-images/system-program-icon.svg';
import easeOfUse from '../website-images/ease-of-use-icon.svg';
import upgradesAndTradeInsIcon from '../website-images/upgrades-and-trade-ins-icon.svg';
import payAsYouUseIcon from '../website-images/pay-as-you-use-icon.svg';
import informationIcon from '../website-images/information-icon.svg';
import gasCylinderStorage from '../website-images/Gas Cylinder Storage.jpg';
import CO2 from '../website-images/CO2.png';
import LPG from '../website-images/LPG.jpg';

// MODELS & SPECS TABLE COMPONENTS
//import LPGEX1Features from '../components/LPGEx-1Features';
//import LPGEx2Features from '../components/LPGEx-2Features';
//import LPGM4MAXXFeatures from '../components/LPGM4-MAXXFeatures';

export default function OtherGasFillingSystem(props) {

    const images = [

        {
        original: '../website-images/OTHER GAS.jpg',
        thumbnail: '../website-images/OTHER GAS.jpg',
        originalWidth: 1024,
        thumbnailWidth: 100
        },
    ]

    // REQUEST A QUOTE MODAL HANDLER
    const HandleOpenModal = () => {
        props.openedModal(true);

        // Save product title to local storage
        let value = document.getElementById('Other-gas-title-id').innerText;
        localStorage.setItem('productTitle', value)
        console.log(value);
    };

  return (
    <div className='LPG-Filling-container'>
    {/* INFO SECTION */}
    <div className='LPG-info-div container'>
        <div className='row'>
        <div className='carousel-div col col-12 col-lg-6'>
            <ImageGallery items={images} />
        </div>
        <div className='col col-12 col-lg-6'>
            <h2 className='LPG-info-title' id='Other-gas-title-id'>OTHER GAS FILLING SYSTEM</h2>
            <p>
                Gas Fill Australia offers a wide variety of gas filling solutions and products that provide a significant advantages for our customers. 
                We offer services and solutions from Gas Blending, Nail Gun Recharging, to Oxygen Filling Solutions and much more. 
                All solutions are following strict standards and compliant to Australian Government Certifications.
                <br /><br />
                Other Gas Filling Systems are a need to know basis as of the moment. This means that if you are interested on our products, 
                you need to reach out to our team for discussion and other inquiries. 
                <br /><br />
                Interested on our products and solutions? Just click the "Request a Quote" button below for our team to connect with you.
            </p>

            <div className='LPG-button-div'>
            <Button className='LPG-request-quote' size='lg' onClick={HandleOpenModal}>REQUEST A QUOTE</Button>
            </div>
        </div>
        </div>
    </div>

    {/* ADVANTAGES SECTION */}
    <div className='LPG-Advantages-div'>
        <h2 className='LPG-advantages-title'>ADVANTAGES</h2>

        <div className='LPG-advantages-content container'>
        <div className='row  align-items-center'>

            <div className='col col-12 col-md-3 p-3 advantages-img-div'>
                <img src={valueForMoneyIcon} className='advantages-img' alt='value for money'/>
                <h4>VALUE FOR MONEY</h4>
            </div>
            <div className='col col-12 col-md-9 p-3'>
                <p>
                    Over their lifetime, our products and solutions are the least expensive to own. 
                    It has been demonstrated that using our goods and solutions can save more than 15kg of gas for every 500kg filled. 
                    The endurance of our systems is ensured by the use of the best components from internationally renowned brands.
                </p>
            </div>

            <div className='col col-12 col-md-3 p-3 order-md-2'>
                <img src={systemProgramIcon} className='advantages-img' alt='system program'/>
                <h4>SYSTEM PROGRAM</h4>
            </div>
            <div className='col col-12 col-md-9 p-3 order-md-1'>
                <p>
                    A specialized application used by Gas Fill Australia may be monitored from any control room and gives managers access to vital facts about 
                    their output performance. displays the entire amount of gas filled in kilograms over the course of a whole day, month, or year, as well as the machine's 
                    active time.
                    <br /><br />
                    Every system we have for Acetylene Filling Systems can use this system program.
                </p>

            </div>
            <div className='col col-12 col-md-3 p-3 order-md-3'>
                <img src={easeOfUse} className='advantages-img' alt='ease of use'/>
                <h4>EASE OF USE</h4>
            </div>
            <div className='col col-12 col-md-9 p-3 order-md-4'>
                <p>
                    The technology offered by Gas Fill Australia is the simplest to use on the market. It only takes a little training to use our system. 
                    By using the tare dials, it is an easy-to-use and instructive system that enables an operator to switch between cylinders, tare weights, 
                    and many other functions.
                    <br /><br />
                    In addition, these technologies have been developed for well over 20 years, and our helpful team has been working with customers to assure usability.
                </p>
            </div>

        </div>
        </div>

    </div>

    {/* MODELS & SPECIFICATIONS SECTION */}
    {/*
    <div className='LPG-models-specs-div'>
        <h2 className='LPG-models-specs-title'>MODELS AND SPECIFICATIONS</h2>
        <Accordion defaultActiveKey={['0']} alwaysOpen className='accordion-div'>
        <Accordion.Item eventKey="0" className='accordion-item'>
            <Accordion.Header className='accordion-header'>EX-1</Accordion.Header>
            <Accordion.Body className='accordion-body'>
            <LPGEX1Features />
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className='accordion-item'>
            <Accordion.Header className='accordion-header'>EX-2</Accordion.Header>
            <Accordion.Body className='accordion-body'>
            <LPGEx2Features />
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className='accordion-item'>
            <Accordion.Header className='accordion-header'>M4 MAXX</Accordion.Header>
            <Accordion.Body className='accordion-body'>
            <LPGM4MAXXFeatures />
            </Accordion.Body>
        </Accordion.Item>
        </Accordion>
    </div>
     */}

    {/* OTHER INFO SECTION */}
    <div className='other-info-div container'>
        <div className='row'>
            <div className='col col-12 col-md-6'>
                <img src={upgradesAndTradeInsIcon}  className='upgrades-and-tradeIns-icon' alt='upgrades and trade ins'/>
                <h3>UPGRADES & TRADE-INS</h3>

                <div>
                <p>
                    Do you have an older machine but not the money to upgrade? Gas Fill Australia have you covered.  
                    We are able to upgrade your old system to our newest systems for a fraction of  the cost of a new system. 
                    We have multiple upgrade options available that can either be done at one of our locations or have an upgrade kit sent to you 
                    for your local electrician to do.
                </p>

                <div className='info-cards'>
                        <img src={informationIcon} className='info-icon' alt='information icon'/>
                        <p>
                            If an upgrade is not something you are after, then you can also look at a trade in on your old system for our new system. Minimum amount 
                            received towards your new purchase is <span>$500</span> on the system.
                        </p>
                </div>
                </div>
            </div>
            <div className='col col-12 col-md-6'>
                <img src={payAsYouUseIcon} className='pay-as-you-use-icon' alt='pay as you can'/>
                <h3>PAY AS YOU USE</h3>

                <div>
                <p>If you are in the market for a reliable system but funds are tight Gas Fill Australia have a system for you. 
                    With our pay as you use system you are charged per cylinder filled. This means during those quiet times our systems costs nothing. 
                    Under this system you automatically gain access to our premium range of machines. This also includes your annual calibration costs and repairs for our machines. 
                    It also means every 5 years you get the benefit of a NEW Machine with no extra charges.  To get more information speak to one of our friendly team.</p>

                    <div className='info-cards'>
                            <img src={informationIcon} className='info-icon' alt='information icon'/>
                            <p>You automatically get access to our premium machine</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* RELATED PRODUCTS SECTION */}
    <div className='related-products-div'>
        <h2>RELATED PRODUCTS</h2>
        <Container className='related-products-cards-container'>
        <Row className='related-products-cards row'>
        <Col sm={4}>
            <Card className="related-products-card text-white">
            <Card.Img src={gasCylinderStorage} alt="Card image" className='related-products-img'/>
            <Card.ImgOverlay className='related-products-overlay'>
                <Card.Title className='related-products-overlay-title'>ACETYLENE GAS FILLING</Card.Title>
                <Card.Text className='related-products-overlay-btn-div'>
                <Link to="/solutions/acetylene-filling-systems"><Button className='related-products-overlay-btn'>VIEW PRODUCT</Button></Link>
                </Card.Text>
            </Card.ImgOverlay>
            </Card>
        </Col>
        <Col sm={4}>
            <Card className="related-products-card text-white">
            <Card.Img src={LPG} alt="Card image" className='related-products-img'/>
            <Card.ImgOverlay className='related-products-overlay'>
                <Card.Title className='related-products-overlay-title'>LPG FILLING SYSTEMS</Card.Title>
                <Card.Text className='related-products-overlay-btn-div'>
                <Link to="/solutions/lpg-filling-systems"><Button className='related-products-overlay-btn'>VIEW PRODUCT</Button></Link>
                </Card.Text>
            </Card.ImgOverlay>
            </Card>
        </Col>
        <Col sm={4}>
            <Card className=" related-products-card text-white">
            <Card.Img src={CO2} alt="Card image" className='related-products-img'/>
            <Card.ImgOverlay className='related-products-overlay'>
                <Card.Title className='related-products-overlay-title'>CO2 GAS FILLING MACHINE FOR INDIVIDUAL/MULTIPLE CYLINDERS</Card.Title>
                <Card.Text className='related-products-overlay-btn-div'>
                <Link to="/solutions/co2-filling-systems"><Button className='related-products-overlay-btn'>VIEW PRODUCT</Button></Link>
                </Card.Text>
            </Card.ImgOverlay>
            </Card>
        </Col>
        </Row>
        </Container>

    </div>
</div>
  )
}
