import React, { useReducer } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import formReducer from '../reducers/FormReducer';
import axios from 'axios';

const initialContactPageState = {
    companyName: "",
    contactPerson: "",
    contactNumber: "",
    emailAddress: "",
    message: "",
};


export default function ContactUsPage(props) {
    
    const [state, dispatch] = useReducer(formReducer, initialContactPageState);

    const handleTextChange = (event) => {
        dispatch({
            type: "HANDLE CONTACT FORM INPUT",
            field: event.target.name,
            payload: event.target.value,
        });
    };

    const handleFormSubmit = (event) => {
        let data = state;
        //console.log(data);
        sendContactForm(data);
        event.preventDefault();
    }

    const sendContactForm = (data) => {
        axios.post(`${process.env.REACT_APP_MAILER_API_URL}/gas-fill-contact-us-form`, data)
        .then(response => {
            let result = response.data;
            if (result.status === "Success") {
                console.log('Email Sent Successfully!');
                props.notifyToastSuccess();
                formReset();
            }else {
                console.log("Error");
                props.notifyToastFailed();
            }
        });
    }

    // RESET FORM FUNCTION
    const formReset = () =>{
        dispatch({type: "HANDLE FORM RESET"});
    }

  return (
    <div className='contact-us-container' id='contact-us'>
        <h2>YOU FIND US CUTE EH? CONTACT US.</h2>
        <p>You can always contact us by filling out form below to receive a call or email from our chill experts. <br/> We promise, they don't bite.</p>

        <form className='request-form' method='POST' onSubmit={handleFormSubmit}>
                <div className='form-container container'>
                    <div className='row'>
                        <div id='form-column-left' className='col col-12 col-md-6'>
                            <TextField
                            type="text"
                            name="companyName"
                            value={state.companyName}
                            className='contact-form-field' 
                            id="outlined-basic" 
                            label="Company Name" 
                            variant="filled" 
                            margin="dense"
                            required
                            onChange={handleTextChange}
                            />
    
                            <TextField
                            type="text"
                            name="contactPerson"
                            value={state.contactPerson}
                            className='contact-form-field' 
                            id="outlined-basic" 
                            label="Contact Person" 
                            variant="filled" 
                            margin="dense"
                            required
                            onChange={handleTextChange}
                            />
    
                            <TextField
                            type="number"
                            name="contactNumber"
                            value={state.contactNumber}
                            className='contact-form-field' 
                            id="outlined-basic" 
                            label="Contact Number" 
                            variant="filled" 
                            margin="dense"
                            sx={{backgroundColor: '#FFFFFF',
                                border: 1 }}
                            required
                            onChange={handleTextChange}
                            />
    
                            <TextField
                            type="email"
                            name="emailAddress"
                            value={state.emailAddress}
                            className='contact-form-field' 
                            id="outlined-basic" 
                            label="Email Address" 
                            variant="filled" 
                            margin="dense"
                            sx={{backgroundColor: '#FFFFFF',
                                border: 1 }}
                            required
                            onChange={handleTextChange}
                            />
                        </div>
    
                        <div id='form-column-right' className='col col-12 col-md-6'>
                            <TextField
                            type="text"
                            name="message"
                            value={state.message}
                            className='contact-form-field' 
                            id="outlined-basic" 
                            label="Message" 
                            variant="filled" 
                            multiline
                            rows={10}
                            margin="dense"
                            onChange={handleTextChange}
                            />
                        </div>
                        
                    </div>
                </div>

                <div className='contact-us-btn-div'>
                    <Button
                        className='contact-us-btn'
                        variant="filled"
                        size='large'
                        color="primary"
                        name='submit' 
                        value='submitRequest'
                        type='submit'
                        /**handles the Google Analytics triggering when the form field (companyName, contactPerson, emailAddress, contactNumber) are all filled out*/
                        onClick={
                            state.companyName.length === 0 ? null 
                            : state.contactPerson.length === 0 ? null 
                            : state.emailAddress.length === 0 ? null 
                            : state.contactNumber.length === 0 ? null 
                            : props.eventTrack.bind(this, "contact_us_page_form_submissions", "forms_submissions", "contact_form")
                        }
                        >
                        HIT ME BABY ONE MORE TIME!
                    </Button>
                </div>

            </form>

    </div>
  )
}
