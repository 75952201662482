import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import headerImage from '../website-images/Gas Cylinder Storage.jpg';

const AcetyleneGasFillingSystemsSolutions = (props) => {
    return (
        <div className='solutions-item-container-left' data-aos="fade-up">
            <div className='solutions-item-header-left'>
                <div className='solutions-item-image-container-left'>
                    <img src={headerImage} alt='lpg filling systems' className='solutions-item-image-left'/>
                </div>
            </div>
            <div className='solutions-item-body-left'>
                <div className='solutions-item-texts-left'>
                    <h2>Acetylene Gas Filling Systems</h2>
                    <p>
                        All new re-acetoning machine is able to handle the addition of acetone into acetylene cylinders in a reliable and fast manner. The GFA's design consists of 
                        a weighing platform, local operator interface (incorporating a message screen and keypad) and an acetone connection in combination with temperature and 
                        pressure measurement capabilities.
                    </p>
                </div>
                <div className='solutions-item-actions-left'>
                <Link to='/solutions/acetylene-filling-systems' className='solution-button-link'>
                    <Button
                        className='check-solutions-left'
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={props.eventTrack.bind(this, "solutions_page_buttons", "button_clicks", "acetylene_checkthisout_button")}
                        >
                        Check This Out
                    </Button>
                </Link>
                </div>
            </div>
        </div>
    );
};

export default AcetyleneGasFillingSystemsSolutions;