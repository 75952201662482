import { React } from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';


import pagenotfound from "../website-images/pagenotfound2.gif";

const PageNotFound = () => {

    return (
        <div className='page-not-found-container'>
            <div className='main-404-document'>
                <div className='page-not-found-image-container'>
                    <img src={pagenotfound} alt='404-not-found' className='page-not-found-image' />
                </div>
                <div className='page-not-found-text-container'>
                    <h1 className='page-not-found-title'><span className='title-header'>Ooopppsss...</span> <span className='title-header' id='not-found'>404</span> <span className='title-header'>Page Not Found.</span></h1>
                    <p className='page-not-found-text'>
                        Mate, You must have gone to a wrong path. 
                        We can't see this page as the URL address is incorrect or does not belong to us.
                        Click the magic button below to teleport on the right path. 
                    </p>

                    <Button 
                    as={Link}
                    to="/"
                    variant="outlined" 
                    id='back-to-home-button'
                    className='page-not-found-button'>
                        Bring me back to home
                    </Button>

                </div>
            </div>
        </div>
    );
};

export default PageNotFound;