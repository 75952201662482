import { React } from 'react';

import LPGFillingSystemsSolutions from '../components/LPGFillingSystemsSolutions';
import CO2GasFillingSystemsSolutions from '../components/CO2GasFillingSystemsSolutions';
import AcetyleneGasFillingSystemsSolutions from '../components/AcetyleneGasFillingSystemsSolutions';
import OtherGasFillingSystemsSolutions from '../components/OtherGasFillingSystemsSolutions';

const SolutionsPage = (props) => {

    return (
        <div className='solutions-page-container' id='solutions'>
            <LPGFillingSystemsSolutions eventTrack={props.eventTrack} />
            <CO2GasFillingSystemsSolutions eventTrack={props.eventTrack} />
            <AcetyleneGasFillingSystemsSolutions eventTrack={props.eventTrack} />
            <OtherGasFillingSystemsSolutions eventTrack={props.eventTrack} />
        </div>
    );
};

export default SolutionsPage;